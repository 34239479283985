@import "../../../../../ui/styles/utils/utils";

.she-page-header {
	align-items: stretch;
	background-color: $color-white;
	bottom: 0;
	box-shadow: $shadow-default;
	display: flex;
	justify-content: stretch;
	left: 0;
	position: fixed;
	width: 100%;
	z-index: get-z-index("nav");

	@include respond-to("small") {
		align-items: flex-start;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: stretch;
		position: static;
		width: 8rem;

		@include respond-to("medium") {
			width: 10rem;
		}

		@include respond-to("x-large") {
			width: 12rem;
		}
	}

	&__inner {
		align-items: stretch;
		background-color: $color-white;
		display: flex;
		flex-grow: 1;
		justify-content: stretch;
		margin: 0;
		overflow: hidden;
		padding: 0;
		width: 100%;
		z-index: get-z-index("nav");

		@include respond-to("small") {
			bottom: initial;
			flex-direction: column;
			justify-content: flex-start;
			min-height: 100vh;
			padding: 0;
			position: fixed;
			position: sticky;
			top: 0;
			width: 8rem;
		}

		@include respond-to("medium") {
			width: 10rem;
		}

		@include respond-to("x-large") {
			width: 12rem;
		}
	}

	&__title {
		color: $color-text-secondary;
		font-size: 1.2rem;
		font-weight: bold;
	}

	&__logo {
		display: none;

		@include respond-to("small") {
			display: flex;
			justify-content: flex-start;
			max-height: 11rem;
			padding: get-spacing("s");
		}
	}

	&__logo-container {
		width: 100%;
	}

	&__logo-image {
		max-width: 6.4rem;
		max-height: 100%;
		display: block;
		margin: auto;

		@include respond-to("medium") {
			max-width: 8.4rem;
		}

		@include respond-to("x-large") {
			max-width: 10.4rem;
		}
	}
}
