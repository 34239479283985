@import "../../styles/utils/_utils";

.translation-text :nth-child(2) {
	margin-top: 30px;
}

#html5qr-code-full-region {
	#qr-shaded-region {
		div {
			background-color: #dabb3e !important;
		}

		:nth-child(3) {
			top: unset !important;
			bottom: -0.25rem !important;
		}

		:nth-child(4) {
			top: unset !important;
			bottom: -0.25rem !important;
		}

		:nth-child(6) {
			top: unset !important;
			bottom: -0.25rem !important;
		}

		:nth-child(8) {
			top: unset !important;
			bottom: -0.25rem !important;
		}
	}
}

.qr-scanner-modal__container {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;

	.qr-scanner-modal__header {
		background-color: $navigation-colours-background;
		display: flex;
		flex: 0 0;
		justify-content: flex-start;
		align-items: center;
		color: $color-white;
		padding: 0.7rem 0.8rem 0.7rem 0.8rem;

		.qr-scanner-modal__header--btn {
			cursor: pointer;
			margin: 0;
			margin-inline-end: get-spacing(s);
			color: $color-white;
			flex: none;
			order: 0;
			flex-grow: 0;
			padding-inline-start: 0.8rem;

			[dir="rtl"] & {
				transform: scaleX(-1);
			}
		}

		.qr-scanner-modal__header--text {
			font-weight: 400;
			font-size: $font-size-h3;
			gap: 1.2rem;
		}
	}

	.qr-scanner-modal__body {
		display: flex;
		flex: 1 1;
		overflow: hidden;
	}

	.qr-scanner-modal__footer {
		position: relative;
		text-align: center;
		background-color: $color-gray-light;
		flex: 0 0;

		.qr-scanner-modal__footer--btn {
			color: $color-black;
			width: 100%;
			font-weight: 700;
			font-size: $font-size-guidance;
			padding: 0.675rem 0 0.675rem 0;
		}
	}
}

.text-field-scanner-container {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.modal-scanner-container {
	box-sizing: border-box;

	.scanner-btn {
		padding: unset;
		width: 2.5rem;
		border: 2px solid $color-gray-dark;
		color: $color-gray-dark;

		&:hover {
			border-color: $system-colours-primary-hover;
			background-color: $color-gray-light;
			color: $color-default-system-colours-text;
		}

		&:active {
			border: 3px solid $system-colours-primary-hover;
			color: $system-colours-primary-hover;
		}

		&:focus {
			border: 3px solid $system-colours-primary-hover;
			color: $color-white;
			background-color: $system-colours-primary;
			outline: none;
		}

		&:disabled {
			background-color: $color-gray-light;
			border: none;
		}

		.scanner-btn-icon {
			aspect-ratio: 1;
			margin: 0 auto;
		}
	}
}
