@import "src/ui/styles/utils/utils";

.she-action-detail {
	.she-action-detail__header-chips {
		> * {
			margin-inline-end: get-spacing("xs");

			span {
				text-transform: uppercase;
			}
		}
	}

	.she-action-detail__title {
		margin: get-spacing("m") 0;
	}

	.she-action-detail__icon {
		padding-left: 0;
		color: $color-gray-dark;

		[dir="rtl"] & {
			padding-right: 0;
			padding-left: get-spacing("xs");
		}
	}

	.she-action-detail__progress {
		margin-top: get-spacing("xs");
	}
}
