.she-components-control {
	@include questionnaire-item-spacing;
	position: relative;

	.she-components-control-required-indicator {
		color: $color-text-required;
		text-decoration: none;
		padding: 0 0.2rem;
	}
}

.she-components-control-question {
	@include questionnaire-item-subitem-margin;
	@include font-body-bold;
}

.she-components-control-hint {
	@include font-hint-normal;
	@include questionnaire-item-subitem-margin;
	color: $color-text-hint;
}

.she-components-control-subtitle {
	@include font-guidance-normal;
	@include questionnaire-item-subitem-margin-top-medium;
	@include questionnaire-item-subitem-margin-bottom-small;
}

.she-components-control-guidance {
	@include questionnaire-item-subitem-margin;
}

.she-components-control-guidance-static {
	@include font-guidance-normal;
	@include questionnaire-item-subitem-margin;
	white-space: pre-wrap;
	word-break: keep-all;
}

.she-components-control-na {
	margin-top: get-spacing("s");
}

.she-components-control-icon {
	top: 0;
	right: 0;
	bottom: 0;
	width: $control-height;
	height: $control-height;
	display: flex;
	pointer-events: none;
	text-align: center;
	line-height: $control-height;
	position: absolute;
	justify-content: center;
	align-items: center;
	padding: 0;

	svg {
		width: 1.2rem;
		height: 1.2rem;
		color: $color-gray-dark;
	}

	@at-root [dir="rtl"] & {
		left: 0;
		right: auto;
	}

	&.she-components-control-icon-interactive {
		pointer-events: all;
	}
}
