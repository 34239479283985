@import "../../styles/utils/utils";

.she-portal-footer-acknowledgement-button {
	text-align: center;
}

.she-license-ul {
	@include questionnaire-item-spacing;
	list-style: none;
}

.she-license-li-full {
	@include input-spacing;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
}

.she-license-nested-ul {
	list-style: none;
	padding: 0;
}

.she-license-nested-li {
	@include questionnaire-item-subitem-margin;
	padding-left: get-spacing("xxxs");
}

.she-license-li-heading {
	font-weight: bold;
}

.she-license-li-url-no-click {
	cursor: context-menu;
	pointer-events: none;
	color: $color-text-primary;
	text-decoration: none !important;
}
