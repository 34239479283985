.audit-page {
	padding: 1rem;
	font-size: 0.8rem;

	h1 {
		margin: 1rem 0 1rem;
	}
	h2 {
		margin: 2rem 0 1rem 0;
		padding: 0;
		font-weight: 600;
	}
	h3 {
		font-weight: 700;
		padding: 0;
		margin: 0;
		font-size: 1rem;
		word-wrap: break-word;
		line-height: 1.4rem;

		span {
			margin-left: 0.5rem;
		}
	}
	ol {
		padding: 0;
		margin: 0;

		li {
			padding: 1rem 2rem 0.5rem 2rem;
			font-size: 0.8rem;
			border-top: 1px solid #ccc;
			position: relative;
			list-style: none;

			&:last-of-type {
				border-bottom: 1px solid #ccc;
			}

			&:before {
				content: counter(list-item) ".";
				position: absolute;
				top: 1rem;
				left: 0.5rem;
				font-size: 1rem;
				font-weight: 700;
				color: #ccc;
				line-height: 1.4rem;
			}

			span {
				font-size: 0.6rem;
				font-weight: 700;
				+ span {
					margin-left: 1rem;
				}
			}
		}
	}

	.audit-page__button-group {
		display: flex;
		flex-wrap: wrap;

		.audit-page__link-button {
			font-size: 0.8rem;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.audit-page__warning {
		color: maroon;
		padding-bottom: 1rem;
		font-weight: bold;
	}
}
