// File: _base.scss
// All basic overwrites and styles

a {
	@include transition-default;
	color: $color-link;
	outline: $outline-width solid transparent;
	outline-offset: 0.1rem;

	&:hover {
		color: $color-link-hover;
	}

	&:focus {
		color: $color-link-focus;
		outline-color: $color-link-focus;
	}
}

blockquote {
	margin: 0;
}

body {
	height: 100%;
	text-align: initial;
	overflow-y: scroll;

	color: $system-colours-text;
}

// Reset button ui
button {
	background: transparent;
	color: inherit;
	border: none;
	cursor: pointer;
	color: $color-link;
	touch-action: manipulation;
	@include button-spacing;

	&:hover {
		color: $color-link-hover;
	}

	&:focus {
		color: $color-link-focus;
		outline-color: $color-link-focus;
	}
}

dl {
	margin: 0;
}

h1 {
	@include font-h1;
	margin-top: get-spacing("s");
	margin-bottom: 0;
}

h2 {
	@include font-h2;
}

h3 {
	@include font-h3;
}

h4 {
	@include font-guidance-normal;
}

// Set basic sizing
html {
	padding: 0;
	margin: 0;
	font-size: $font-size-screen-xsmall;
	color: $color-text-primary;
	height: 100%;

	@include respond-to("x-small") {
		font-size: $font-size-screen-small;
	}

	@include respond-to("small") {
		font-size: $font-size;
	}
}

input,
select,
textarea {
	background: #fff;
	border-radius: 0;
	-webkit-appearance: none;
}

label {
	margin: 0;
	padding: 0;
}

ol {
	margin: 0;
}

p {
	margin: 0;
}

svg {
	display: block;
}

ul {
	margin: 0;
}

[dir="rtl"] {
	direction: rtl;
}

::-ms-clear {
	display: none;
}
