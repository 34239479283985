@import "../../../styles/utils/utils";

.she-matrix-input-text {
	margin: 0 get-spacing("xs");

	color: $color-text-secondary;
}

.she-matrix-input-color {
	width: 1.5rem;
	height: 1.5rem;
}
