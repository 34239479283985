@import "../../styles/utils/utils";

.she-portal-page__container {
	margin-top: get-spacing("m");
}

.she-portal-page__title-bar-slot {
	display: flex;
	flex-direction: row;
	align-items: center;
}
