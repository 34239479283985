.she-btn-disabled {
	background-color: $color-gray-dark !important;
}

.she-features-wrapper {
	margin: 1rem;
}

.she-features-button {
	width: 100%;
}

.she-available-features-header {
	font-weight: bold;
	font-size: 1.5rem;
}
