@import "../../../../styles/utils/utils";

$questionnaire-item-height-small: 8rem;
$questionnaire-item-height-large: 8rem;

.she-policy-list {
	@include questionnaire-item-spacing;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -0.4rem;

	@include respond-to("medium") {
		margin: -0.6rem;
	}
}

.she-policy-list-modal-item {
	padding: 0.8rem 1.6rem;
	border-bottom: 0.1rem solid #687073;

	.she-policy-list-item__text--heading {
		margin-top: 0;
	}
}

.she-policy-list-item {
	padding: 0.4rem;
	position: relative;
	width: 100%;

	@include respond-to("medium") {
		padding: 0.6rem;
	}

	@include respond-to("x-large") {
		width: 50%;
	}

	&__wrap {
		@include transition-default;
		background: $color-gray-lightest;
		box-shadow: $shadow-default;
		display: flex;
		flex-direction: column;
		outline: $outline-width solid transparent;
		padding: 0 get-spacing("s") get-spacing("s");

		@include respond-to("medium") {
			height: $questionnaire-item-height-large;
		}
	}

	&__text {
		@include font-guidance-normal;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--heading {
			font-weight: bold;
		}
	}

	&__button-container {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		margin-top: get-spacing("s");
	}
}

.attachment-list {
	.she-policy-list-modal-header {
		@include font-body-normal;
		padding-bottom: 1rem;
	}

	.she-policy-list-item {
		@include respond-to("x-large") {
			width: 100%;
		}
	}

	.she-policy-list-item {
		all: unset;
		.she-policy-list-item__wrap {
			all: unset;
		}
	}
}
