.she-components-text-input {
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
	@include input-spacing;
	@include questionnaire-item-subitem-margin;
	display: block;
	resize: none;
	width: 100%;
	overflow: hidden;
	border-radius: 0;
	background-color: $color-white;
	color: $color-text-secondary;
	min-height: 2.5rem;

	&[aria-invalid="true"] {
		@include input-focus-hover-invalid;
	}

	&.she-components-text-input-pad-right {
		padding-inline-end: $control-height;
	}
}
