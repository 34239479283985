@import "../../../../styles/utils/utils";

$className: "she-module-settings-card";
$settings-card-height-small: 3rem;
$settings-card-height-large: 3rem;

.#{$className} {
	@include transition-default;
	background-color: $color-white;
	align-items: stretch;
	box-shadow: $shadow-default;
	display: flex;
	width: 100%;
	padding: 0;
	margin: 0.4rem 0;

	@include respond-to("x-large") {
		width: 50%;
	}

	&__body {
		text-align: start;
		display: column;
		flex-direction: row;
		overflow: hidden;
		padding: get-spacing("xs") get-spacing("s");
	}

	&__title {
		@include font-body-bold;
		margin: 0;
		font-size: 0.9rem;
		overflow: hidden;
		word-wrap: break-word;
	}

	&__subtitle {
		color: $color-gray-dark;
		@include font-body-normal;
		margin: 0;
		font-size: 0.7rem;
		overflow: hidden;
		word-wrap: break-word;
	}

	&__options {
		margin: 0;
		padding: get-spacing("xs") 0;
	}

	&__thumbnail {
		padding: 0.8rem;
		color: $system-colours-primary;
		flex-shrink: 0;
		height: $settings-card-height-small;
		width: $settings-card-height-small;
		overflow: hidden;

		@include respond-to("medium") {
			height: $settings-card-height-large;
			width: $settings-card-height-large;
		}

		svg {
			@include transition-long;
		}
	}

	&__thumbnail-image {
		@include transition-long;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}
