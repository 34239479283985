@import "../../../../styles/utils/utils";

.she-questionnaire-header-container {
	display: flex;
	align-items: center;

	height: $header-height;
	padding: 0 get-spacing("s");

	background-color: $navigation-colours-background;
	color: $navigation-colours-text-icon;

	@include respond-to("small") {
		padding: 0 get-spacing("ml");
	}

	h3 {
		overflow: hidden;
		margin: 0;

		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.she-questionnaire-header-icon {
		margin: 0;
		margin-inline-end: get-spacing(s);
		cursor: pointer;

		[dir="rtl"] & {
			transform: scaleX(-1);
		}
	}
}

.she-questionnaire-header-icon {
	color: $navigation-colours-text-icon;

	svg {
		fill: $navigation-colours-text-icon;
	}
}
