@import "../../../styles/utils/utils";

// Button
@mixin she-btn-bottom-line($background-color: $color-black) {
	&:before {
		@include transition-default("background-color");
		content: "";
		display: block;

		position: absolute;
		bottom: -0.1rem;
		left: -0.1rem;
		right: -0.1rem;

		height: 0.1rem;

		background-color: $background-color;
	}
}

.she-button {
	display: flex;
	align-items: center;

	position: relative;

	border: get-spacing("xxxs") solid transparent;
	border-radius: 0;
	outline: 0.15rem solid transparent;
	outline-offset: -(get-spacing("xxxs"));

	cursor: pointer;
	user-select: none;
	transition: all 0.15s ease-out;

	@include button-spacing;
	@include font-body-normal;

	-webkit-appearance: none;
	touch-action: none;

	&.she-button-primary {
		background-color: $color-button-primary-background;
		color: $color-button-primary-text;

		&:focus {
			color: $color-button-primary-text;
		}
	}

	&.she-button-secondary {
		background-color: $color-button-secondary-background;
		border: get-spacing("xxxs") solid currentColor;
		color: $color-button-secondary-text;

		&:hover,
		&:focus {
			color: $navigation-colours-background;
		}
	}

	&.she-button-danger {
		color: $color-button-primary-text;
		border-bottom: get-spacing("xxxs") solid $color-button-primary-border-bottom;
		background-color: $color-control-border-error;
	}

	&.she-button-tertiary {
		@include font-guidance-normal;
		@include button-spacing;
		flex-basis: auto;

		background-color: transparent;
		color: $system-colours-primary;

		&:hover {
			color: $system-colours-primary;
		}

		&:focus {
			color: $system-colours-primary-hover;
		}
	}

	&.she-button-tertiary-inverted {
		@include font-guidance-normal;
		@include button-spacing;

		border: get-spacing("xxxs") solid transparent;

		background-color: transparent;
		color: $navigation-colours-text-icon;

		&:hover {
			border-color: $navigation-colours-background-hover;
			background-color: transparent;
			color: $navigation-colours-text-icon-hover;
		}

		&:focus {
			border-color: $navigation-colours-background-hover;
			outline: 0.15rem solid white;
			background-color: transparent;
			color: $navigation-colours-text-icon-hover;
		}
	}

	&.she-button-limited {
		color: $color-gray-dark;

		&:hover {
			border-color: $color-gray-dark;
			color: $color-gray-dark;
		}
	}

	&:focus {
		background-color: $color-link-focus;
		outline: 0.15rem solid $color-link;
		color: $color-button-focus-text;

		&:before {
			background-color: transparent;
		}
	}

	&:hover {
		border: get-spacing("xxxs") solid $color-link-focus;

		&:before {
			background-color: transparent;
		}
	}

	.she-button-icon {
		margin: #{- (get-spacing("xxs"))} #{0} #{- (get-spacing("xxs"))} #{get-spacing("xs")};
		padding: 0;

		[dir="rtl"] & {
			margin: #{- (get-spacing("xxs"))} #{get-spacing("xs")} #{- (get-spacing("xxs"))} #{0};
		}

		svg {
			width: 1.2rem;
			height: 1.2rem;
		}
	}

	&.she-button-tertiary {
		.she-button-icon {
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	&.she-button-awaiting {
		background-color: $color-link-focus;
		outline: 0.15rem solid $color-link;
		color: $color-button-focus-text;
		border-bottom: get-spacing("xxxs") solid $color-button-primary-border-bottom;

		&:after {
			background-color: $color-white;
			content: "";
			display: block;
			position: absolute;
			bottom: -0.1rem;
			left: 0%;
			right: 100%;
			height: 0.15rem;
			animation-name: button-slide;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
		}
	}

	&.she-button-nav {
		@include font-guidance-normal;
		@include button-spacing;
		flex-basis: auto;

		color: $navigation-colours-text-icon;

		&:focus,
		&:hover {
			outline: solid 0.1rem currentColor;

			background-color: transparent;
			color: $navigation-colours-background-hover;
		}

		&:focus {
			outline: solid 0.15rem currentColor;
		}
	}

	@keyframes button-slide {
		0% {
			left: -0.1rem;
			right: calc(100% + 0.1rem);
		}

		33% {
			left: -0.1rem;
		}

		50% {
			right: -0.1rem;
		}

		67% {
			left: calc(100% + 0.1rem);
		}

		100% {
			left: calc(100% + 0.1rem);
			right: -0.1rem;
		}
	}
}
