@import "../../../styles/utils/utils";

.she-portal-footer-app-id {
	text-align: center;
	padding-bottom: get-spacing("m");
}

.she-app-id-label {
	@include font-hint-normal;
	@include questionnaire-item-subitem-margin;
	color: $color-text-hint;
}
