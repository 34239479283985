@import "../../styles/utils/utils";

.she-password-page-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	width: 100%;

	@include respond-to("small") {
		//ie 11 sizing fix when attachments are added
		max-width: 35rem;
	}

	@include respond-to("x-large") {
		//ie 11 sizing fix when attachments are added
		max-width: 33rem;
	}

	.she-password-page-container {
		@include questionnaire-page-spacing;
		flex-grow: 1;
		padding: 0;
	}
}
