@import "../../styles/utils/utils";

.she-question-icon {
	width: 5rem;
	height: 5rem;
	cursor: pointer;
	margin-inline-start: get-spacing("s");

	svg {
		@include transition-default;
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}

	&.she-question-icon--selected {
		svg {
			opacity: 1;
		}
	}
}
