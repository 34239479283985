.she-components-checkbox-group {
	padding: 0;
}

.she-components-checkbox {
	@include questionnaire-item-subitem-margin;
	display: flex;
	position: relative;
	padding: 0;

	color: $color-text-secondary;

	&:hover label:before {
		border-color: $color-control-border-hover;
	}

	input {
		width: $control-height;
		height: $control-height;
		opacity: 0;
		z-index: get-z-index("form-item");
		cursor: pointer;
		margin: 0;
		padding: 0;
		flex-shrink: 0;
		flex-grow: 0;

		&:checked + label:after {
			border-left: 0.3rem solid $color-radio-border-hover;
			border-bottom: 0.3rem solid $color-radio-border-hover;
			width: 1.4rem;
			height: 1rem;
			transition: height 0.05s linear, width 0.1s linear 0.05s;

			@at-root [dir="rtl"] & {
				transition: none;
			}
		}

		&:hover + label:before {
			border: $border-width solid $color-radio-border-hover;
			outline: $outline-width solid transparent;
		}

		&:focus + label:before {
			outline: $outline-width solid $color-radio-border-focus;
		}
	}

	label {
		@include font-body-normal;
		margin: 0;
		padding-top: 0.55rem;

		&:before {
			@include transition-default;
			@include pseudo-element;
			position: absolute;
			left: 0rem;
			top: 0.15rem;
			width: 2.2rem;
			height: 2.2rem;
			border: $border-width solid $color-control-border-normal;
			box-sizing: border-box;
			z-index: 0;
			outline: $outline-width solid transparent;
			outline-offset: $outline-offset;
			background-color: $color-white;

			@at-root [dir="rtl"] & {
				left: auto;
				right: 0rem;
			}
		}

		&:after {
			@include pseudo-element;
			overflow: hidden;
			position: absolute;
			left: 0.2rem;
			top: 1.1rem;
			width: 0rem;
			height: 0.3rem;
			transform: rotate(-45deg);
			transform-origin: 0 0;
			z-index: 0;
			border-left: 0.3rem solid #fff;
			border-bottom: 0.3rem solid #fff;
			transition: width 0.06s linear, height 0.03s linear 0.06s,
				border-color 0.03s linear 0.09s;
			box-sizing: border-box;
			direction: ltr;

			@at-root [dir="rtl"] & {
				left: auto;
				right: 0.5rem;
				transition: none;
			}
		}
	}
}
