@import "../../../styles/utils/_utils.scss";

.she-number-input {
	@include questionnaire-item-subitem-margin;
	display: flex;
	width: 100%;

	button {
		@include input-focus-hover;
		@include transition-default;
		display: block;
		padding: get-spacing("xs");
		width: $control-height;
		height: $control-height;
		flex-grow: 0;
		flex-shrink: 0;
		text-align: center;
		background-color: $color-button-tertiary-background;
		cursor: pointer;
		color: $color-text-primary;

		&:focus {
			z-index: get-z-index("form-item");
		}

		@media not screen {
			display: none;
		}
	}

	input {
		@include input-spacing;
		@include transition-default;
		@include input-focus-hover;
		display: block;
		text-align: center;
		margin: 0 (-$border-width);
		z-index: 0;
		width: $control-small-width;
		border-radius: 0;

		@media screen, print {
			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		&:focus {
			z-index: get-z-index("form-item");
		}

		&.she-number-input-no-step {
			width: $control-medium-width;
			text-align: left;
		}
	}
}
