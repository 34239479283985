@import "../../../styles/utils/utils";

.she-spinner {
	overflow: hidden;
	height: 4rem;
	margin-top: get-spacing(s);

	color: $system-colours-primary;

	svg {
		animation: angle 1.5s linear infinite;
		width: 4rem;
		height: 4rem;
	}

	.she-spinner-background {
		stroke-width: 10;
		stroke: currentColor;
		fill: transparent;
		opacity: 0.4;
	}

	.she-spinner-circle {
		stroke-dasharray: 100 900;
		stroke-dashoffset: 0;
		animation: dash 2s linear alternate infinite;
		transform-origin: 50% 50%;
		stroke-linecap: round;
		stroke-width: 10;
		stroke: currentColor;
		fill: transparent;
	}
}

@keyframes dash {
	from {
		stroke-dasharray: 0 1000;
		stroke-dashoffset: 0;
	}
	to {
		stroke-dasharray: 1000 0;
		stroke-dashoffset: 500;
	}
}

@keyframes angle {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
