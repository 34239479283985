@import "../../styles/utils/utils";

.she-login-button__container {
	display: flex;
	margin-inline-end: get-spacing("s");
	color: #fff;
	align-items: center;

	div {
		margin-inline-end: get-spacing("s");
	}
}

.she-login-button__new-version-button {
	margin-bottom: get-spacing("l");
}
