@import "../../../styles/utils/utils";

.she-components-validation-message.searching-message {
	color: $color-gray-dark;
	margin-top: 16px;

	&::before {
		background-color: $color-gray-dark;
		margin-top: 10px;
		margin-inline-end: 0.4rem;
	}
}

.btnFocus button {
	background-color: $color-link-focus;
	outline: 0.15rem solid $color-link;
	color: $color-button-focus-text;
}

.block {
	display: block;
}

.no-margin {
	margin: 0;
}

.dont-display-asterix abbr {
	display: none;
}

.leaflet-container {
	a {
		border: get-spacing("xxxs") solid transparent;
		cursor: pointer;
		transition: all 0.15s ease-out;
		outline: 0.15rem solid transparent;
		outline-offset: -(get-spacing("xxxs"));
		position: relative;
		flex-basis: 50%;
		user-select: none;

		&:focus {
			background-color: $color-link-focus;
			outline: 0.15rem solid $color-link;
			color: $color-button-focus-text;

			&:before {
				background-color: transparent;
			}
		}

		&:hover {
			border: get-spacing("xxxs") solid $color-link-focus;

			&:before {
				background-color: transparent;
			}
		}
	}

	.leaflet-control-zoom {
		margin-inline-end: 16px;
		margin-bottom: 4px;

		a {
			line-height: 26px;
		}
	}
}

.modalMapWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;

	.modalMapContainer {
		flex-basis: 100%;
		display: flex;
		flex-direction: column;
		justify-content: stretch;

		.leaflet-container {
			flex-basis: 100%;
			margin-top: 0;
		}

		.positionWrapper {
			flex-grow: 0;
			margin-bottom: 0;
		}
	}

	.modalMapDetails {
		flex-grow: 0;
		@include questionnaire-container-spacing;

		@media (max-height: 460px) {
			padding-top: get-spacing("xxs");
			padding-bottom: get-spacing("xxs");
		}

		.she-components-control-question {
			margin-bottom: get-spacing("xxs");
		}
	}
}

.readOnlyMapContainer {
	.leaflet-marker-icon {
		cursor: default;
	}

	.leaflet-container {
		margin-top: get-spacing("xxs");
		padding-top: 60%;
		z-index: 1;

		.leaflet-control {
			pointer-events: none;
		}
	}
}

.positionWrapper {
	text-align: center;
	background-color: $color-gray-light;
	padding: get-spacing("xxs");
	margin-bottom: get-spacing("xxs");
	@include font-guidance-normal;
}

.geo-position-input__button {
	margin-bottom: get-spacing("xs");
}

.geo-position__read-only-map__controls {
	margin-bottom: get-spacing("xs");
}
