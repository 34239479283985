.she-components-dropdown-inline {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&.she-components-dropdown-inline-reverse {
		flex-direction: row-reverse;
	}

	.she-components-dropdown {
		select {
			width: auto;
		}
	}

	label {
		margin: 0 get-spacing("xs") 0 0;
		padding: get-spacing("xs") 0 get-spacing("xs");

		color: $color-text-secondary;

		@at-root [dir="rtl"] & {
			margin: 0 0 0 get-spacing("xs");
		}
	}
}

.she-components-dropdown {
	position: relative;

	label {
		margin: 0 get-spacing("xs");
	}

	select {
		@include transition-default(("outline", "border"));
		@include input-focus-hover;
		@include questionnaire-item-subitem-margin;
		width: 100%;
		appearance: none;
		position: relative;
		padding: get-spacing("xs") (get-spacing("xs") + $control-height) get-spacing("xs")
			get-spacing("xs");

		@include respond-to("small") {
			padding: get-spacing("xs") (get-spacing("xs") + $control-height) get-spacing("xs")
				get-spacing("s");
		}

		@at-root [dir="rtl"] & {
			padding: get-spacing("xs") get-spacing("xs") get-spacing("xs")
				(get-spacing("xs") + $control-height);

			@include respond-to("small") {
				padding: get-spacing("xs") get-spacing("s") get-spacing("xs")
					(get-spacing("xs") + $control-height);
			}
		}

		option:hover {
			background-color: $system-colours-primary;
		}

		&::-ms-expand {
			display: none;
		}
	}
}
