@import "../../../../../styles/utils/utils";

.she-section-mobile-nav {
	position: sticky;
	top: 0;
	width: 100%;
	box-shadow: 0 0 0rem rgba(0, 0, 0, 0.16);
	z-index: get-z-index("form-index");

	@media (max-height: 20rem) {
		position: relative;
	}

	.she-section-mobile-nav-overlay {
		position: absolute;
		content: "";
		top: -3.2rem;
		left: 0;
		right: 0;
		height: 0;
		background-color: rgba(0, 0, 0, 0);
		display: block;
		z-index: -1;
		transition: height 0s ease-out 200ms, background-color 150ms linear 50ms;
		touch-action: none;
	}

	&.she-section-mobile-nav-open {
		box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.16);

		.she-section-mobile-nav-overlay {
			position: absolute;
			content: "";
			top: -3.2rem;
			left: 0;
			right: 0;
			height: calc(100vh + 3.2rem);
			background-color: rgba(0, 0, 0, 0.5);
			display: block;
			z-index: -1;
			transition: height 0s ease-out, background-color 150ms linear;
		}
	}

	.she-section-mobile-nav-list {
		position: absolute;
		padding: 0rem;
		overflow: hidden;
		transition: all 0.2s ease-out;
		background-color: $color-white;
		top: 100%;
		left: 0;
		right: 0;

		&.she-section-mobile-nav-list-open {
			height: auto;
		}

		ul {
			list-style: none;
			padding: 0rem;
			margin: 0rem;
			border-bottom: $border-width solid $color-gray-dark;
			max-height: calc(100vh - 3.2rem);
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}

		li {
			&:last-of-type {
				.she-section-mobile-nav-list-item {
					border: none;
				}
			}

			.she-section-mobile-nav-list-item {
				@include font-guidance-normal;
				@include transition-default(("border", "outline", "background-color"));

				padding: get-spacing("s") get-spacing("s");

				@include respond-to("small") {
					padding: get-spacing("xs") get-spacing("s");
				}
				width: 100%;
				align-items: center;
				text-align: inherit;
				min-height: 2.4rem;
				border-bottom: $border-width solid $color-gray-light;
				display: flex;
				align-items: center;
				outline: $outline-width solid transparent;
				outline-offset: -($outline-width);
				color: $color-text-primary;

				&:hover {
					background-color: $color-gray-light;
					color: $color-text-primary;
				}

				&:focus {
					outline-color: $color-link-focus;
					color: $color-text-primary;
				}

				&.she-section-mobile-nav-list-item-current {
					font-weight: $font-weight-bold;
				}
			}

			.she-section-mobile-nav-list-item-validation {
				&:after {
					@include validation-mark;
					margin-inline-start: get-spacing("xs");
				}
			}
		}
	}

	.she-section-mobile-nav-current {
		padding: 0.2rem 0.8rem;
		height: 3.2rem !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
		cursor: pointer;
		background-color: #dee0e2;
		transition: all 0.15s ease-out;

		&:hover {
			background-color: #d4d5d6;
		}

		.she-section-mobile-nav-current-details {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			align-self: flex-start;
			padding-top: 5px;
			overflow: auto;
		}

		.she-section-mobile-nav-icon {
			width: 30px;
			transition: 0.5s;
			-webkit-transition: 0.5s;
			-moz-transition: 0.5s;
			-ms-transition: 0.5s;
			-o-transition: 0.5s;
		}

		.she-section-mobile-nav-icon-opened {
			transition: 0.5s;
			-webkit-transition: 0.5s;
			-moz-transition: 0.5s;
			-ms-transition: 0.5s;
			-o-transition: 0.5s;
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}

		.she-section-mobile-nav-current-name {
			font-size: 0.8rem;
			line-height: 1.2rem;
			height: 1.2rem;
			padding: 0rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.she-section-mobile-nav-current-number {
			font-size: 0.7rem;
			line-height: 1rem;
			padding: 0rem;
		}
	}
}

.she-components-navigation-body #root {
	overflow: hidden;
	height: 100vh;
}
