@import "../../styles/utils/utils";

.she-home-page__background {
	min-height: 100vh;
	background-color: $color-gray-lighter;
}

.she-home-page__container {
	max-width: 60rem;
	margin: 0 auto;
	position: relative;
	min-height: 95vh;
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 0 get-spacing("xl") 0;

	@include respond-to("small") {
		padding: 0;
	}
}

.she-home-page__container__column {
	@include portal-page-spacing;
}
