@import "../../styles/utils/utils";

.she-layout-form {
	background-color: $color-gray-lighter;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	&__nav {
		background-color: $color-white;
	}

	&__content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-height: 95vh;
		overflow: auto;
		padding: 0 0 get-spacing("xl") 0;
		position: relative;

		@include respond-to("small") {
			padding: 0;
		}
	}

	&__content-column {
		@include portal-page-spacing;

		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: stretch;
		margin: 0 auto;
		max-width: 60rem;
		width: 100%;
	}

	&__content-topbar {
		align-content: space-between;
		align-items: center;
		background-color: $system-colours-primary;
		display: flex;
		padding: get-spacing(xs) get-spacing(s);

		@include respond-to("small") {
			height: 64px;
		}
	}

	&__content-topbar-title {
		color: $color-white;
		font-size: $font-size-h3;
		font-weight: $font-weight-semibold;
		line-height: 1;
		margin: 0;
		padding: 0;
	}

	&__content-topbar-slot {
		margin-left: auto;
	}

	&__footer {
		margin: auto;
	}
}
