@import "../../../styles/utils/utils";

.booleanGroup {
	display: flex;
	flex-wrap: wrap;
}

.booleanRadio {
	padding-right: $control-height;

	@at-root [dir="rtl"] & {
		padding-right: 0;
		padding-left: $control-height;
	}
}
