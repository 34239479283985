@import "src/ui/styles/utils/utils";

$imageDimensions: 7rem;

.training-card {
	display: flex;
	position: relative;
	box-shadow: $shadow-default;
	background-color: $color-white;
	color: var(--system-colours-text);
	font-size: 0.8rem;
	transition: all 0.15s ease-out;

	&:hover {
		text-decoration: none;
		color: var(--system-colours-text);
	}

	&:not(.training-card--unlinked):hover {
		background: $color-white;
		box-shadow: $shadow-strong;
		color: $color-text-primary;
	}

	&:not(.training-card--unlinked):focus {
		color: $color-text-primary;
	}

	&__image {
		display: block;
		width: $imageDimensions;
		height: $imageDimensions;
		object-fit: cover;
	}

	&__content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: get-spacing("xs") get-spacing("s");
	}

	&__info {
		flex-grow: 1;
		display: flex;
		flex-direction: column-reverse;
		gap: get-spacing("s");

		@include respond-to("large") {
			flex-direction: row;
		}
	}

	&__heading {
		flex-grow: 1;
	}

	&__title {
		font-weight: 700;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__unlinked {
		font-weight: 700;
		color: $color-danger;
	}

	&__status {
		display: flex;
		align-items: center;
		gap: get-spacing("xs");
		white-space: nowrap;

		&::after {
			content: "";
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			background-color: $color-disabled;
		}

		&--in-progress::after {
			background-color: $color-warning;
		}

		&--completed::after {
			background-color: $color-success;
		}
	}

	&__due-date {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: get-spacing("s");
		font-weight: 600;
	}

	&__overdue {
		overflow: hidden;
		position: absolute;
		left: calc(#{$imageDimensions} - #{get-spacing("xs")});
		transform: translateX(-100%);
		width: auto;
		max-width: calc(7rem - #{get-spacing("xs") * 2});
		padding: get-spacing("xxs") get-spacing("xs");
		background-color: $color-danger;
		color: $color-white;
		font-size: 0.7rem;
		text-transform: uppercase;
		border-radius: 0.2rem;
		white-space: nowrap;
		text-overflow: ellipsis;

		@include respond-to("large") {
			position: static;
			transform: none;
			max-width: 100%;
		}
	}
}
