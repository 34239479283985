@import "../../styles/utils/utils";

.she-download-list {
	padding: 0;
	margin: 0;
	list-style: none;
}

.she-download-list__empty {
	@include font-guidance-normal;
	padding: 0.8rem 1.6rem;
	line-height: 1.5;
}

.she-download-list__item {
	padding: 0.8rem 1.6rem;
	border-bottom: 0.1rem solid #687073;

	.she-download-list__buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 0.4rem;

		button {
			margin-inline-start: 0.4rem;
		}
	}

	h3,
	div {
		@include font-guidance-normal;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0;
	}

	h3 {
		font-weight: bold;
	}
}

.she-download-toast__body {
	display: flex;
	justify-content: stretch;
	align-items: center;
	min-height: 2rem;

	.she-icon {
		flex-grow: 0;
		flex-shrink: 0;
		justify-self: flex-end;

		[dir="rtl"] & {
			transform: scaleX(-1);
		}
	}

	.she-download-toast__text {
		width: 100%;
		flex-grow: 1;
	}

	.she-download-toast__spinner {
		width: 36px;
		height: 36px;
		margin-inline-end: 0.4rem;
		flex-grow: 0;
		flex-shrink: 0;

		.she-spinner {
			width: 36px;
			height: 36px;
			margin: 0;

			svg {
				width: 36px;
				height: 36px;
			}
		}
	}
}
