.__markerjs2_ {
	z-index: 1000000 !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	width: 100% !important;
	height: 100% !important;
	position: fixed !important;

	> div {
		justify-content: center;
		align-items: center;
		width: 100% !important;
		height: 100% !important;

		> div {
			background-color: transparent !important;
			align-items: center;
			justify-content: center;

			> div {
				flex-grow: 0 !important;
			}
		}
	}

	.__markerjs2__1_toolbox_colors {
		height: 52px;
	}

	.__markerjs2_toolbar {
		height: 3rem !important;
		align-items: flex-start;
		justify-content: stretch;
		flex-direction: row-reverse;

		.__markerjs2_toolbar-block {
			justify-content: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			flex-grow: 2 !important;
		}

		.__markerjs2_toolbar-block:first-of-type {
			justify-content: flex-start;
			flex-grow: 0 !important;
		}

		.__markerjs2_toolbar-block:last-of-type {
			justify-content: flex-end;
			flex-grow: 0 !important;
		}
	}

	.__markerjs2_toolbar_button {
		width: 2.6rem;
		height: 3.2rem;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 1.3rem;
			height: 1.3rem;
		}
	}

	.__markerjs2_toolbox {
		height: 3.2rem;
		align-items: flex-start;
		justify-content: space-between;

		> div {
			flex-grow: 2 !important;
		}

		.__markerjs2_toolbox-panel-row {
			bottom: 3.2rem;
		}

		.__markerjs2_toolbox-button-row {
			background-color: transparent !important;
		}
	}

	.__markerjs2_toolbox_button {
		width: 2.6rem;
		height: 3.2rem;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 1.3rem;
			height: 1.3rem;
		}
	}
}
