@import "../../../../../styles/utils/utils";

.Modal {
	display: flex;
	flex-direction: column;
	text-align: center;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;

	border: black;
	border-style: solid;
	border-width: 1px;

	margin: auto;

	height: 100%;
	width: 100%;

	@include questionnaire-container-spacing;

	@include respond-to("small") {
		max-width: 520px;
		max-height: 200px;
	}

	.buttonbar {
		margin-top: auto;
		display: flex;
		align-items: center;

		justify-content: center;
		flex-direction: column;

		@include respond-to("small") {
			flex-direction: row;
		}

		> button {
			@include button-spacing;
			margin-inline-end: 0.3em;
			margin-block-end: 0.5em;
			width: 100%;

			@include respond-to("small") {
				margin-block-end: 0;
				width: 50%;
			}
		}
	}
}
