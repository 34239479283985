@import "src/ui/styles/utils/utils";

.attachments-detail {
	display: flex;
	align-items: center;

	svg {
		display: inline;
		width: 1.2rem;
		height: 1.2rem;
		margin-inline-end: 0.4rem;
	}

	svg:nth-child(2) {
		margin-inline-start: 0.8rem;
	}
}

.she-record-card {
	box-shadow: $shadow-default;
	padding: get-spacing("s");
	background-color: $color-white;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	@include font-guidance-normal();

	&__header {
		display: flex;
		margin-bottom: get-spacing("s");
		justify-content: space-between;
		width: 100%;
	}

	&__header-ref {
		color: $color-text-secondary;
	}

	&__body {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__list-item-detail {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-inline-start: 0;
	}

	&__list-attachments {
		padding: 0;
		margin: 0;
		display: block;
	}

	&__list-attachments-item {
		padding: 0;
		margin: 0;
		display: inline;
	}

	&__list-attachments-control {
		padding: 0 get-spacing("s");
		margin: 0;
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: center left;
		color: inherit;

		&:hover {
			color: inherit;
			text-decoration: none;
		}

		&--files {
			background-image: url("../../../../../../src/ui/assets/svg/attachment-icon.svg");
		}

		&--links {
			background-image: url("../../../../../../src/ui/assets/svg/link-icon.svg");
		}
	}

	&__button-container {
		justify-content: flex-end;
		display: flex;
		flex-direction: row;
		margin-top: auto;
		flex-wrap: wrap;

		.she-button {
			margin-top: get-spacing("xxs");

			&:last-child {
				margin-inline-start: get-spacing("xs");
			}

			&:first-child {
				margin-inline-start: 0;
			}
		}
	}
}
