@import "../../styles/utils/utils";

.field {
	@include questionnaire-item-spacing;
	position: relative;

	label {
		@include questionnaire-item-subitem-margin;
		@include font-body-bold;
	}

	&.sheControlError {
		position: relative;
		&:before {
			content: "";
			width: 0.15rem;
			position: absolute;
			top: 0.2rem;
			left: -0.6rem;
			bottom: 0.2rem;
			display: block;
			border-left: 0.15rem solid #d0021b;
		}
	}

	.sheControlGuidance {
		@include font-guidance-normal;
		@include questionnaire-item-subitem-margin;
	}

	.sheControlValidation {
		@include font-hint-bold;
		@include questionnaire-item-subitem-margin;
		color: $color-text-error;
		display: flex;

		&:before {
			background-color: $color-text-error;
			border-radius: 50%;
			color: $color-white;
			content: "!";
			flex-grow: 0;
			flex-shrink: 0;
			height: 1rem;
			display: block;
			text-align: center;
			width: 1rem;
			margin-inline-end: get-spacing("xxs");
		}
	}

	.sheRequiredIndicator {
		color: $color-text-required;
		text-decoration: none;
	}
}
