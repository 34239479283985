@import "src/ui/styles/utils/utils";

.action-comments-card {
	display: grid;
	gap: get-spacing("xxs");
	padding: get-spacing("s");
	background: $color-white;
	border-bottom: 0.1rem solid #687073;
	font-size: 0.8rem;
	color: $system-colours-text;
	transition-property: box-shadow, background-color;
	transition-duration: $transition-default-duration;
	transition-timing-function: $transition-default-easing;

	outline: $outline-width solid transparent;
	outline-offset: 0;

	@include respond-to("small") {
		padding: get-spacing("s") get-spacing("ml");
	}

	&:hover {
		background-color: $color-gray-lightest;
		color: $system-colours-text;
		text-decoration: none;
	}

	&__header {
		display: flex;
	}

	&__title {
		flex-grow: 1;
		font-weight: 700;
	}

	&__tag {
		padding: get-spacing("xxs") get-spacing("xs");
		border-radius: 0.2rem;
		color: $color-white;
		font-size: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;

		&--edited {
			background-color: $color-warning;
		}

		&--deleted {
			background-color: $color-danger;
		}
	}

	&__date {
		font-weight: 500;
	}

	&__description {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.7rem;
		word-break: break-word;
	}

	&__deleted-by {
		margin-top: get-spacing("xxs");
		font-size: 0.7rem;
	}
}
