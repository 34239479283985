@import "../../../styles/utils/utils";

.she-portal-toast-container {
	position: fixed;
	bottom: 3.15rem;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column-reverse;
	align-items: stretch;
	z-index: get-z-index("toast");
	pointer-events: none;

	@include respond-to("small") {
		bottom: 0;
	}

	> * {
		pointer-events: all;
	}
}

.she-toast-list__wrapper {
	width: 100%;
	max-width: 48.5rem;
	display: flex;
	margin: auto;
	padding: 0;

	@include respond-to("small") {
		&:before {
			display: block;
			content: "";
			width: 8rem;
			flex-grow: 0;
			flex-shrink: 0;

			@include respond-to("medium") {
				width: 10rem;
			}

			@include respond-to("x-large") {
				width: 12rem;
			}
		}
	}
}

.she-toast-list {
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
}

.she-layout-form + .she-portal-toast-container {
	bottom: 2.75rem;

	@include respond-to("small") {
		bottom: 2.4rem;
	}
}

.she-toast {
	flex-grow: 1;
	padding: get-spacing("xs") get-spacing("s");
	border-bottom: 0.1rem solid $color-white;
	width: 100%;
	background-color: $color-gray-dark;
	color: $color-white;
	pointer-events: all;
	cursor: pointer;
	min-height: 2.9rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	white-space: pre-wrap;
	word-break: keep-all;

	@include font-guidance-normal;

	> * {
		pointer-events: all;
	}

	&.she-toast--error {
		background-color: $color-danger;
	}

	&.she-toast--success {
		background-color: $system-colours-primary;
	}
}
