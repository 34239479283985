// File: _sizes.scss
// This file contains sizes, dimentions and spacing variables.

// Screen width breakpoints
$breakpoints: (
	"x-small": 370px,
	"smallMinusOne": 575px,
	"small": 576px,
	"medium": 768px,
	"large": 992px,
	"x-large": 1200px,
);

$height-breakpoints: (
	"x-small": 370px,
);

:export {
	breakpointXs: map-get($breakpoints, "x-small");
	breakpointS1: map-get($breakpoints, "smallMinusOne");
	breakpointS: map-get($breakpoints, "small");
	breakpointM: map-get($breakpoints, "medium");
	breakpointL: map-get($breakpoints, "large");
	breakpointXl: map-get($breakpoints, "x-large");
}

@mixin respond-to($breakpoint) {
	// Retrieves the value from the key
	$value: map-get($breakpoints, $breakpoint);

	// If the key exists in the map
	@if $value != null {
		// Prints a media query based on the value
		@media (min-width: $value) {
			@content;
		}
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}

$control-small-width: 9rem;
$control-medium-width: 14rem;
$control-height: 2.5rem;
$form-topbar-height: 3.2rem;

$spacings: (
	xxxs: 0.1rem,
	xxs: 0.2rem,
	xs: 0.4rem,
	ms: 0.6rem,
	s: 0.8rem,
	m: 1.2rem,
	ml: 1.6rem,
	l: 2.4rem,
	xl: 3.2rem,
	xxl: 4.8rem,
);

@function get-spacing($spacing) {
	// Retrieves the value from the key
	$value: map-get($spacings, $spacing);

	// If the key exists in the map
	@if $value != null {
		// Prints a media query based on the value
		@return $value;
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$spacing}`. "
        + "Please make sure it is defined in `$spacings` map.";
	}
}

$header-height: get-spacing("xl");

@mixin questionnaire-item-spacing {
	padding: get-spacing("s") 0;
}

@mixin questionnaire-item-subitem-margin {
	margin: 0 0 get-spacing("xxs") 0;
}

@mixin questionnaire-item-subitem-margin-bottom-small {
	margin-bottom: get-spacing("xxs");
}

@mixin questionnaire-item-subitem-margin-top-medium {
	margin-top: get-spacing("xs");
}

@mixin questionnaire-container-spacing {
	padding: get-spacing("s");

	@include respond-to("small") {
		padding: get-spacing("ml");
	}
}

@mixin portal-page-spacing {
	padding: get-spacing("s");

	@include respond-to("small") {
		padding: get-spacing("s") get-spacing("ml");
	}
}

@mixin questionnaire-page-spacing {
	padding: 0 0 get-spacing("xl") 0;

	@include respond-to("small") {
		padding: 0 0 get-spacing("xl") 0;
	}
}

@mixin button-spacing {
	padding: get-spacing("ms") get-spacing("s");

	@include respond-to("small") {
		padding: get-spacing("xs") get-spacing("s");
	}
}

@mixin input-spacing {
	padding: get-spacing("xs");

	@include respond-to("small") {
		padding: get-spacing("xs") get-spacing("s");
	}
}

// Borders
$border-width: 0.1rem;
$outline-width: 0.15rem;
$outline-offset: -0.1rem;
$border-radio-width: 0.05rem;

$z-indexes: (
	behind: -1,
	form-item: 1,
	form-controls: 100,
	nav-secondary: 105,
	nav: 110,
	form-popup: 150,
	form-index: 200,
	toast: 250,
	modal: 300,
	in-front: 1000,
);

@function get-z-index($z-index) {
	// Retrieves the value from the key
	$value: map-get($z-indexes, $z-index);

	// If the key exists in the map
	@if $value != null {
		// Prints a media query based on the value
		@return $value;
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$z-index}`. "
        + "Please make sure it is defined in `$z-indexes` map.";
	}
}

@mixin pseudo-element {
	position: absolute;
	content: "";
	display: block;
}

@mixin layout-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
