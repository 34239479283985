@import "../../../../../styles/utils/utils";

.she-section-nav {
	overflow-y: auto;
	min-height: calc(100vh);
	position: sticky;
	top: 0;
	background-color: $color-white;
	box-shadow: $shadow-default;

	@include respond-to("small") {
		width: 8rem;

		@include respond-to("medium") {
			width: 10rem;
		}

		@include respond-to("x-large") {
			width: 12rem;
		}
	}

	.she-section-nav-list {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			.she-section-nav-list-item {
				@include font-guidance-normal;
				@include transition-default(("border", "outline", "background-color"));

				align-items: center;
				border-bottom: $border-width solid $color-gray-light;
				color: $color-text-primary;
				display: flex;
				min-height: 2.4rem;
				outline-offset: -($outline-width);
				outline: $outline-width solid transparent;
				padding: get-spacing("xs") get-spacing("s");
				text-align: inherit;
				width: 100%;

				&:hover {
					background-color: $color-gray-light;
					color: $color-text-primary;
				}

				&:focus {
					outline-color: $color-link-focus;
					color: $color-text-primary;
				}

				&.she-section-nav-list-item-current {
					font-weight: $font-weight-bold;
				}
			}
		}

		.she-section-nav-list-item-validation {
			&:after {
				@include validation-mark;
				margin-inline-start: get-spacing("xs");
			}
		}
	}
}
