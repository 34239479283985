@import "../../../../styles/utils/utils";

// Icon sizes
$questionnaire-item-height-small: 6rem;
$questionnaire-item-height-large: 7rem;

.she-questionnaire-record {
	width: 100%;
	cursor: pointer;
	padding: 0.4rem;
	position: relative;

	@include respond-to("medium") {
		padding: 0.6rem;
	}

	@include respond-to("x-large") {
		width: 50%;
	}

	> div {
		transition: background $transition-default-duration $transition-default-easing,
			box-shadow $transition-default-duration $transition-default-easing,
			background-color $transition-very-long-duration ease-out;
		background-color: $color-gray-lightest;
		background: transparent;
		box-shadow: $shadow-default;
		display: flex;
		flex-direction: row;
		height: $questionnaire-item-height-small;
		outline: $outline-width solid transparent;

		@include respond-to("medium") {
			height: $questionnaire-item-height-large;
		}

		.she-questionnaire-record-body {
			padding: get-spacing("xs") get-spacing("s");
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-shrink: 1;
			flex-grow: 1;
			overflow: hidden;

			.she-questionnaire-record-body-title {
				@include font-body-bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.she-questionnaire-record-body-data {
				@include font-guidance-normal;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.she-questionnaire-record-options-menu {
			flex-grow: 0;
			padding: 0.15rem;
			button {
				float: right;
				svg {
					height: 2rem;
					@include transition-default;
					color: $color-gray-dark;
				}

				&:hover {
					svg {
						color: $color-link-focus;
					}
				}
			}
		}

		.she-portal-record-options-wrapper {
			@include transition-default;
			@include questionnaire-item-subitem-margin;
			@include transition-default(("outline", "border"));
			@include input-focus-hover;
			background: $color-white;
			z-index: 100;
			top: 0;
			right: 0;
			position: absolute;
			margin: 0.4rem;
			display: none;

			[dir="rtl"] & {
				right: auto;
				left: 0;
			}

			@include respond-to("medium") {
				margin: 0.6rem;
			}

			.she-portal-record-options {
				padding-inline-start: 0;

				.she-portal-record-option {
					width: 100%;
					display: flex;
					align-items: center;

					&:hover {
						background-color: $color-gray-light;
						color: $color-text-primary;
					}

					button {
						@include button-spacing;
						width: 100%;
					}

					div {
						float: left;

						span {
							vertical-align: middle;
							color: black;
						}
					}
				}
			}
		}

		&:hover {
			background: $color-white;
			box-shadow: $shadow-strong;

			.she-portal-questionnaire-thumbnail-image,
			.she-portal-questionnaire-thumbnail svg {
				transform: scale(1.075);
			}
		}

		&:focus {
			outline-color: $color-control-border-focus;
		}
	}

	&.she-questionnaire-record--highlight > div {
		background-color: $system-colours-background;
	}
}

.she-portal-questionnaire-options-overlay {
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 80;
}

.she-portal-record-thumbnail-showOptions {
	display: block !important;
}
