@import "../../../styles/utils/utils";

// Chip

.she-chip {
	padding: get-spacing("xxs") get-spacing("xs");
	position: relative;
	background-color: $color-gray-dark;
	color: $color-white;
	display: inline-block;
	border-radius: 4px;

	@include font-chip-normal;

	&.she-chip-primary {
		background-color: $color-chip-primary-background;
	}
	&.she-chip-secondary {
		background-color: $color-chip-secondary-background;
	}
	&.she-chip-error {
		background-color: $color-chip-error-background;
	}
}
