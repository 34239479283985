@import "../../../../styles/utils/utils";

.she-portal-header-top {
	flex-direction: row;
	flex-wrap: wrap;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.she-portal-header {
	width: 100%;
	padding: 0 0 get-spacing("m") 0;

	.she-portal-description {
		max-width: 40rem;

		> * {
			margin-bottom: get-spacing("xxs");
		}

		margin-bottom: get-spacing("s");

		blockquote {
			margin: get-spacing("s") 0;
			padding: get-spacing("s") get-spacing("s");
			border-left: $outline-width solid $color-gray-light;
			background-color: $color-white;
		}
	}
}
