@import "../../../../styles/utils/utils";

// Icon sizes
$questionnaire-item-height-small: 6rem;
$questionnaire-item-height-large: 7rem;

.she-portal-questionnaire {
	width: 100%;
	cursor: pointer;
	padding: 0.4rem;
	position: relative;

	@include respond-to("medium") {
		padding: 0.6rem;
	}

	@include respond-to("x-large") {
		width: 50%;
	}

	> a {
		transition: background $transition-default-duration $transition-default-easing,
			box-shadow $transition-default-duration $transition-default-easing,
			background-color $transition-very-long-duration ease-out;
		background-color: $color-gray-lightest;
		background: transparent;
		box-shadow: $shadow-default;
		display: flex;
		flex-direction: row;
		height: $questionnaire-item-height-small;
		outline: $outline-width solid transparent;
		text-decoration: none;
		color: $system-colours-text;
		padding-inline-end: 2.3rem;
		outline-offset: 0;

		&:hover {
			background: $color-white;
			box-shadow: $shadow-strong;
			color: $color-text-primary;

			.she-portal-questionnaire-thumbnail-image,
			.she-portal-questionnaire-thumbnail svg,
			.she-module-card__thumbnail-image,
			.she-module-card__thumbnail svg {
				transform: scale(1.075);
			}
		}

		&:focus {
			outline-color: $color-control-border-focus;
			color: $color-text-primary;
		}

		@include respond-to("medium") {
			height: $questionnaire-item-height-large;
		}

		.she-portal-questionnaire-body {
			padding: get-spacing("xs") get-spacing("s");
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-shrink: 1;
			overflow: hidden;

			.she-portal-questionnaire-body-title {
				@include font-body-bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.she-portal-questionnaire-body-description {
				@include font-guidance-normal;
				overflow: hidden;
				display: -webkit-box;
				max-height: $line-height-guidance * 2;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				word-wrap: break-word;
				width: 100%;

				@include respond-to("medium") {
					max-height: $line-height-guidance * 3;
					-webkit-line-clamp: 3;
				}
			}

			.she-portal-questionnaire-body-data {
				@include font-guidance-normal;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		&.she-portal-questionnaire--highlight {
			background-color: $system-colours-primary;
		}
	}

	.she-portal-questionnaire-options-menu {
		flex-grow: 1;
		padding: 0.15rem;
		position: absolute;
		top: 0.6rem;
		right: 0.6rem;

		[dir="rtl"] & {
			left: 0.6rem;
			right: auto;
		}

		button {
			float: right;
			svg {
				height: 2rem;
				@include transition-default;
				color: $color-gray-dark;
			}

			&:hover {
				svg {
					color: $color-link-focus;
				}
			}
		}
	}

	.she-portal-questionnaire-options-wrapper {
		@include transition-default;
		@include questionnaire-item-subitem-margin;
		@include transition-default(("outline", "border"));
		@include input-focus-hover;
		background: $color-white;
		z-index: 100;
		top: 0;
		right: 0;
		position: absolute;
		margin: 0.4rem;
		display: none;

		[dir="rtl"] & {
			left: 0;
			right: auto;
		}

		@include respond-to("medium") {
			margin: 0.6rem;
		}

		.she-portal-questionnaire-options {
			padding-inline-start: 0;

			.she-portal-questionnaire-option {
				width: 100%;
				display: flex;
				align-items: center;

				button {
					@include button-spacing;
					width: 100%;

					&:hover {
						background-color: $system-colours-primary;
						color: $navigation-colours-text-icon-hover;
					}
				}

				div {
					text-align: left;

					span {
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.she-portal-questionnaire-availibility-indicator {
	> span {
		border-radius: 50%;
		border-width: 1px;
		border-style: solid;
		width: 12px;
		height: 12px;
		margin-inline-end: get-spacing("xxs");
		margin-inline-start: get-spacing("s");
		display: inline-block;
		background-color: $color-danger;
		border-color: $color-danger;
	}

	.she-portal-questionnaire-availibility-indicator-offline {
		background-color: $color-success;
		border-color: $color-success;
	}
}

.she-portal-questionnaire-options-overlay {
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 80;
}

.she-portal-questionnaire-thumbnail-showOptions {
	display: block !important;
}
