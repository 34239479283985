// File: _typgraphy.scss
// This file contains all the typography rules and variables.

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;

// Every other size in the application is based on $font-size configured here (apart from screen size). 1rem = 20px
$font-size: 20px;
$font-size-screen-small: 18px;
$font-size-screen-xsmall: 17px;

// Font sizes
$font-size-body: 1rem;
$font-size-guidance: 0.8rem;
$font-size-hint: 0.7rem;
$font-size-progress: 0.6rem;
$font-size-h1: 2rem;
$font-size-h2: 1.4rem;
$font-size-h3: 1.2rem;

// Font weights
$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-semibold: 400;
$font-weight-bold: bold;

// Line heights
$line-height-body: 1.4rem;
$line-height-guidance: 1.2rem;
$line-height-hint: 1rem;
$line-height-h1: 2.4rem;
$line-height-h2: 2.1rem;
$line-height-h3: 1.6rem;

//Mixins
@mixin font-body-normal {
	font-size: $font-size-body;
	line-height: $line-height-body;
	font-weight: $font-weight-normal;
}

@mixin font-body-bold {
	font-size: $font-size-body;
	line-height: $line-height-body;
	font-weight: $font-weight-bold;
}

@mixin font-guidance-normal {
	font-size: $font-size-guidance;
	line-height: $line-height-guidance;
	font-weight: $font-weight-normal;
}

@mixin font-guidance-bold {
	font-size: $font-size-guidance;
	line-height: $line-height-guidance;
	font-weight: $font-weight-bold;
}

@mixin font-hint-normal {
	font-size: $font-size-hint;
	line-height: $line-height-hint;
	font-weight: $font-weight-normal;
}

@mixin font-hint-bold {
	font-size: $font-size-hint;
	line-height: $line-height-hint;
	font-weight: $font-weight-bold;
}

@mixin font-chip-normal {
	font-size: $font-size-hint;
	line-height: $line-height-hint;
	font-weight: $font-weight-bold;
}

@mixin font-progress-normal {
	font-size: $font-size-progress;
	font-weight: $font-weight-bold;
}
@mixin font-chip-normal {
	font-size: $font-size-hint;
	line-height: $line-height-hint;
	font-weight: $font-weight-bold;
}

@mixin font-h1 {
	font-size: $font-size-h1;
	line-height: $line-height-h1;
	font-weight: $font-weight-normal;
}

@mixin font-h2 {
	font-size: $font-size-h2;
	line-height: $line-height-h2;
	font-weight: $font-weight-normal;
}

@mixin font-h3 {
	font-size: $font-size-h3;
	line-height: $line-height-h3;
	font-weight: $font-weight-normal;
}
