@import "../../../../styles/utils/utils";

.she-questionnaire-list {
	@include questionnaire-item-spacing;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -0.4rem;

	@include respond-to("medium") {
		margin: -0.6rem;
	}
}

.she-questionnaire-list-sort-container {
	justify-content: flex-end;
}

.she-questionnaire-list__search {
	margin-bottom: get-spacing("s");
}

.she-questionnaire-list__empty {
	padding-top: get-spacing("s");
}

.she-questionnaire-list__sort-wrapper__header {
	display: flex;
	align-items: center;
}

.she-questionnaire-list__sort-wrapper {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;

	@include respond-to("x-large") {
		flex-direction: row;
		justify-content: space-between;
	}
}
