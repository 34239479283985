.she-components-radio-group {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.she-components-radio-group-button {
	@include questionnaire-item-subitem-margin;
	display: flex;
	position: relative;

	input {
		width: $control-height;
		height: $control-height;
		opacity: 0;
		z-index: get-z-index("form-item");
		cursor: pointer;
		margin: 0;
		padding: 0;
		flex-shrink: 0;
		flex-grow: 0;

		// Pseudo radio button fill when checked
		&:checked + label:after {
			background-color: $color-radio-border-hover;
			border: 0.35rem solid $color-radio-background;
		}

		// Pseudo radio button border when focused
		&:focus + label:before {
			border: $border-radio-width solid $color-radio-border-focus;
			background: $color-radio-border-focus;
		}
	}

	label {
		@include font-body-normal;
		margin: 0;
		padding-top: 0.55rem;

		color: $color-text-secondary;

		// Pseudo radio button border
		&:before {
			@include transition-default;
			@include pseudo-element;
			left: -0.05rem;
			top: 0.15rem;
			width: 2.2rem;
			height: 2.2rem;
			border: 0.05rem solid $color-radio-background;
			border-radius: 50%;
			box-sizing: border-box;
			z-index: 0;
			background: $color-radio-border-normal;

			@at-root [dir="rtl"] & {
				left: auto;
				right: -0.05rem;
			}
		}

		// Pseudo radio button fill
		&:after {
			@include transition-default;
			@include pseudo-element;
			left: 0.1rem;
			top: 0.3rem;
			width: 1.9rem;
			height: 1.9rem;
			border-radius: 50%;
			z-index: 0;
			background-color: $color-radio-background;
			border: 0.95rem solid $color-radio-background;

			@at-root [dir="rtl"] & {
				left: auto;
				right: 0.1rem;
			}
		}
	}
}
