@import "../../../../../ui/styles/utils/utils";

.she-main-nav {
	min-width: 100%;

	.she-main-nav__group {
		display: flex;
		flex-grow: 1;
		margin: 0;
		padding: 0;

		@include respond-to("small") {
			flex-direction: column;
		}
	}

	.she-main-nav__item {
		position: relative;
		align-items: stretch;
		display: flex;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 2;
		justify-content: stretch;
		list-style: none;
		margin: 0;
		overflow: hidden;
		padding: 0;

		@include respond-to("small") {
			flex-basis: auto;
			flex-grow: 0;
		}
	}

	.she-main-nav__item-link {
		@include font-guidance-normal;
		@include transition-default(("border", "outline", "background-color"));

		align-items: center;
		color: $system-colours-primary;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 2.8rem;
		outline-offset: -($outline-width);
		outline: $outline-width solid transparent;
		overflow: hidden;
		padding: get-spacing("xs") get-spacing("s");
		text-align: inherit;
		text-decoration: none;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		&:hover {
			background-color: $color-gray-light;
			color: $system-colours-primary;
		}

		&:focus {
			color: $system-colours-primary;
			outline-color: $system-colours-primary;
		}

		&--selected {
			@include font-guidance-bold;
		}

		@include respond-to("small") {
			align-items: center;
			flex-basis: 100%;
			flex-direction: row;
			justify-content: flex-start;
			padding: get-spacing("xs") get-spacing("s") get-spacing("xs") 0;

			[dir="rtl"] & {
				padding: get-spacing("xs") 0 get-spacing("xs") get-spacing("s");
			}
		}
	}

	.she-main-nav__item-link-label {
		display: block;
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;

		@include respond-to("small") {
			overflow-y: auto;
			overflow-x: hidden;
			text-align: left;
			text-overflow: initial;
			white-space: normal;
			width: auto;

			[dir="rtl"] & {
				text-align: right;
			}
		}
	}

	.she-main-nav__item-link-pill {
		padding: 0 0.3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-danger;
		color: $color-white;
		font-weight: $font-weight-semibold;
		font-size: $font-size-hint;
		border-radius: 0.5rem;
		height: 1rem;
		min-width: 1rem;
		position: absolute;
		left: 50%;
		top: 0.15rem;
		margin-inline-start: 0.2rem;

		@include respond-to("small") {
			margin-inline-start: get-spacing("xs");
			position: static;
		}
	}

	.she-icon {
		margin: 0 get-spacing("s");
		padding: 0;
	}
}
