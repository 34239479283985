@mixin validation-mark {
	background-color: $color-text-error;
	border-radius: 50%;
	color: $color-text-inverted;
	content: "!";
	flex-grow: 0;
	flex-shrink: 0;
	display: block;
	text-align: center;
	height: 1rem;
	width: 1rem;
	line-height: 1rem;
	font-weight: bold;
}
