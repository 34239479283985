@import "../../../styles/utils/utils";

.she-ppe-input {
	padding: get-spacing("xs");
	border: $border-width solid $color-gray-light;
	margin-bottom: get-spacing("xs");
	min-height: 5.8rem;

	.she-ppe-input_container {
		width: 100%;
	}

	.she-ppe-input__icon {
		flex-grow: 0;
		padding: get-spacing("xxs") 0;
	}

	.she-ppe-input__controls {
		display: flex;
		flex-grow: 2;
		justify-content: stretch;
	}
}
