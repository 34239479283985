@use "sass:math";

.she-container {
	max-width: 60rem;
	margin: auto;
	position: relative;
}

.she-full-height-container {
	max-width: 60rem;
	margin: 0 auto;
	position: relative;
	min-height: 95vh;
}

.she-row {
	display: flex;
	flex-direction: row;
	padding: 0;
	flex-wrap: wrap;

	@include respond-to("small") {
		flex-wrap: nowrap;
	}
}

.she-col {
	flex-grow: 1;
}

.she-col-4 {
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;

	@include respond-to("small") {
		width: math.div(4, 12) * 100%;
	}
}

.she-layout-relative {
	position: relative;
}

.clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.she-layout-text-center {
	text-align: center;
	white-space: pre-wrap;
	word-break: keep-all;
}

.she-layout-flex {
	flex: 1;
}

.she-layout-flex-end {
	justify-content: flex-end;
	display: flex;
	flex-grow: 1;
}

.she-layout-flex-center {
	justify-content: center;
	display: flex;
	flex-grow: 1;
}
