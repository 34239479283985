@import "../../../styles/utils/utils";

.she-control-hint {
	@include font-hint-normal;
	@include questionnaire-item-subitem-margin;
	color: $color-text-hint;
}

.she-time-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}

.she-time-button-wrapper {
	align-items: center;
	height: 2.5rem;
	padding-bottom: 0.1rem;
}

.she-components-text-input {
	&__time-inline {
		max-width: 3.75rem;
		margin-inline-end: 0.8rem;

		&--long {
			max-width: 5rem;
		}

		&--very-long {
			max-width: 7rem;
			min-width: 5.5rem;
		}
	}

	&__time-label {
		font-size: smaller;
		display: block;
	}
}
