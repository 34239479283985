@import "../../../styles/utils/utils";

.she-file-input {
	[type="file"] {
		height: 0;
		overflow: hidden;
		width: 0;
		display: block;
		margin-left: -100rem;

		&:focus + label {
			outline: $outline-width solid $color-button-focus-border;
			background-color: $color-link-focus;
			color: $system-colours-primary-hover;
		}
	}
}

.she-large-file-wrapper {
	.she-large-file {
		@include transition-default;
		@include questionnaire-item-subitem-margin;
		@include transition-default(("outline", "border"));
		width: 100%;
		padding: 1rem;
		border: 0.1rem solid $color-white;
		margin-bottom: get-spacing("s");
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}
}
