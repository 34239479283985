@import "../../styles/utils/utils";

.action-attachment-item {
	@include questionnaire-item-subitem-margin;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	border: $border-width solid $color-control-border-normal;
	min-height: 2.6rem;
	padding: get-spacing("xs") get-spacing("l") get-spacing("xs") get-spacing("xs");
	list-style: none;

	[dir="rtl"] & {
		padding: get-spacing("xs") get-spacing("xs") get-spacing("xs") get-spacing("l");
	}

	.action-attachment-item__name {
		color: $color-gray-dark;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}

	.action-attachment-item__description {
		margin-top: get-spacing("xxs");
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}

	.action-attachment-item__menu {
		position: absolute;
		top: get-spacing("xxxs");
		right: get-spacing("xxxs");

		[dir="rtl"] & {
			right: auto;
			left: get-spacing("xxxs");
		}
	}
}
