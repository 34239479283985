@import "../../styles/utils/utils";

.she-thumbnail-list__wrapper {
	margin: 0;
	list-style: none;
	padding-inline-start: 0;
	width: 100%;
	display: flex;
	flex-direction: column;

	.she-thumbnail-list__image-wrapper {
		@include transition-default(("outline", "border"));
		position: relative;
		display: block;
		width: 100%;
	}
}

.she-thumbnail-list__empty-state {
	margin-top: get-spacing("s");
}

.she-thumbnail-list__guidance {
	@include font-hint-normal;
	margin: get-spacing("xs") 0;
}
