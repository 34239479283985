@import "src/ui/styles/utils/utils";

.training-page {
	&__offline,
	&__loading,
	&__cards {
		margin-top: get-spacing("m");
	}

	&__offline,
	&__loading {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	&__cards {
		display: grid;
		gap: get-spacing("m");
	}

	&__offline-title {
		font-size: 16px;
		font-weight: 700;
	}

	&__offline-description {
		margin-top: get-spacing("xs");
		font-size: 14px;
		font-weight: 500;
	}

	&__empty {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: get-spacing("m");
	}

	&__empty-inner {
		display: grid;
		gap: get-spacing("xs");
		max-width: 15rem;
	}

	&__empty-illustration {
		margin-inline: auto;

		&--mobile {
			@include respond-to("large") {
				display: none;
			}
		}

		&--desktop {
			display: none;

			@include respond-to("large") {
				display: block;
			}
		}
	}

	&__empty-title {
		font-size: 0.8rem;
		font-weight: 700;
	}

	&__empty-description {
		font-size: 0.8rem;
	}
}
