@import "../../../../../styles/utils/utils";

.Modal {
	display: flex;
	flex-direction: column;
	background-color: $color-white;
	height: 100%;
	width: 100%;

	@include respond-to("small") {
		height: auto;
		max-width: 32rem;
		max-height: 95vh;
	}

	.headerContainer {
		min-height: 3.2rem;
		display: flex;
		justify-content: stretch;
		align-items: center;

		h3 {
			margin: 0;
			padding: get-spacing("xxs") get-spacing("s");

			@include respond-to("small") {
				padding: get-spacing("xxs") get-spacing("ml");
			}
		}
	}

	.subModuleFields {
		@include questionnaire-container-spacing;
		overflow: auto;

		.validationErrors {
			padding: get-spacing("s");
		}
	}

	.buttonbar {
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		> button {
			width: 50%;
		}
	}
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-black-transparent;
}
