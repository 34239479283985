@import "../../styles/utils/utils";

.she-attachment-description-modal-item {
	margin-bottom: get-spacing("l");

	&:last-of-type {
		margin-bottom: 0;
	}

	img {
		max-height: 6rem;
		max-width: 10rem;
	}

	.she-components-control-subtitle {
		&.she-attachment-description__filename {
			color: $color-gray-dark;
			margin-bottom: 0;
		}

		&.she-attachment-description__label {
			font-weight: $font-weight-bold;
			margin-top: 0;
		}
	}

	.she-components-text-input.mb {
		margin-bottom: get-spacing("l");

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.she-attachment-description-modal__h3 {
	margin-bottom: get-spacing("s");
}
