@import "../../../styles/utils/_utils.scss";

.skip-to-main {
	@include transition-default(("top"));
	padding: get-spacing("s");
	position: absolute;
	top: -100%;
	left: 0;
	z-index: get-z-index("in-front");
	background-color: $system-colours-background;

	&:focus-within {
		top: 0;
	}

	a {
		@include button-spacing();
		display: inline-block;
	}
}
