@import "../../../../styles/utils/utils";

$questionnaire-item-height-small: 6rem;
$questionnaire-item-height-large: 7rem;

.she-portal-questionnaire-thumbnail {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	background-color: $color-white;

	height: $questionnaire-item-height-small;
	width: $questionnaire-item-height-small;
	overflow: hidden;

	@include respond-to("medium") {
		height: $questionnaire-item-height-large;
		width: $questionnaire-item-height-large;
	}

	.she-portal-questionnaire-thumbnail-image {
		@include transition-long;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 100%;
	}

	svg {
		@include transition-long;
		width: 60%;
	}
}
