@import "../../styles/utils/utils";

.she-components-card {
	@include input-focus-hover;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));

	background-color: $color-white;
	display: flex;
	justify-content: stretch;
	position: relative;
	width: 100%;

	&[aria-invalid="true"] {
		@include input-focus-hover-invalid;
	}

	&__content {
		@include input-spacing;

		width: 100%;
	}

	&__button-slot {
		position: absolute;
		right: 0;

		[dir="rtl"] & {
			right: auto;
			left: 0;
		}
	}

	&--clickable {
		cursor: pointer;
		user-select: none;
	}

	dl,
	ul,
	ol {
		@include font-guidance-normal;

		flex-grow: 1;
		margin-inline-end: 1.5rem;
	}

	dt {
		font-weight: $font-weight-bold;
		word-break: break-all;
	}

	dd,
	li {
		@include questionnaire-item-subitem-margin;

		word-break: break-all;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
