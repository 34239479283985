// File: _borders.scss
// Borders and outlines

@mixin input-focus-hover {
	border: $border-width solid $color-control-border-normal;
	outline: $outline-width solid transparent;
	outline-offset: $outline-offset;

	&:focus {
		outline-color: $color-control-border-focus;
	}

	&:hover {
		border-color: $color-control-border-hover;
	}
}

@mixin input-focus-hover-invalid {
	border: $border-width solid $color-control-border-error;
	outline: $outline-width solid $color-control-border-error;
	outline-offset: $outline-offset;

	&:focus {
		outline-color: $color-control-border-focus;
	}

	&:hover {
		border-color: $color-control-border-hover;
	}
}

@mixin input-focus-disabled {
	border: $border-width solid $color-control-border-normal;
	outline: $outline-width solid transparent;
	outline-offset: $outline-offset;

	&:focus {
		outline: transparent;
	}

	&:hover {
		border-color: $color-control-border-normal;
	}
}
