@import "../../../styles/utils/utils";

.she-project-list-item {
	border-top: $border-width solid $color-control-border-normal;
	padding: get-spacing("xs") get-spacing("s");

	@include respond-to("small") {
		padding: get-spacing("xs") get-spacing("ml");
	}
}
