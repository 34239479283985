@import "../../styles/utils/utils";

.she-layout-page {
	background-color: $color-gray-lighter;
	min-height: 100vh;

	&__content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		min-height: 100vh;
		padding: 0 0 get-spacing("xl") 0;
		position: relative;
		width: 100%;

		@include respond-to("small") {
			padding: 0;
			width: calc(100% - 8rem);
		}

		@include respond-to("medium") {
			width: calc(100% - 10rem);
		}

		@include respond-to("x-large") {
			width: calc(100% - 12rem);
		}
	}

	&__content-column {
		@include portal-page-spacing;

		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: stretch;
		margin: 0 auto;
		max-width: 60rem;
		width: 100%;
	}

	&__content-topbar {
		align-content: space-between;
		align-items: center;
		background-color: $navigation-colours-background;
		display: flex;
		flex-shrink: 0;
		height: 3.2rem;
		padding: 0 get-spacing("s");

		@include respond-to("small") {
			padding: 0 get-spacing("ml");
		}
	}

	&__content-topbar-title {
		color: $navigation-colours-text-icon;
		flex: 1;
		font-size: $font-size-h3;
		font-weight: $font-weight-semibold;
		line-height: 1.3;
		margin: 0;
		overflow: hidden;
		padding: 0 get-spacing(s) 0 0;
		text-overflow: ellipsis;
		white-space: nowrap;

		[dir="rtl"] & {
			padding: 0 0 0 get-spacing(s);
		}
	}

	&__content-topbar-slot {
		margin-left: auto;
	}

	&__content-topbar-icon-slot {
		cursor: pointer;
		margin: 0 get-spacing(s) 0 0;

		[dir="rtl"] & {
			margin: 0 0 0 get-spacing(s);

			.she-layout-page__back-icon {
				transform: scaleX(-1);
			}
		}
	}

	&__footer {
		margin-top: auto;
	}
}
