@import "../../../styles/utils/utils";

.she-list {
	@include questionnaire-item-subitem-margin;
	padding: 0;

	li {
		display: flex;
		flex-direction: column;
	}
}
