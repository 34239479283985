@import "../../../styles/utils/utils";

.she-tree {
	@include respond-to("small") {
		padding: get-spacing("ml");
	}

	.she-tree-indent {
		.she-tree-indent {
			margin-left: 2.2rem;

			@at-root [dir="rtl"] & {
				margin-right: 2.2rem;
				margin-left: 0;
			}
		}
	}
}

.she-tree-row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&.she-tree-root {
		border-bottom: $color-gray-dark 0.1rem solid;
		@include questionnaire-container-spacing; // supplies 0.8rem padding all over
		padding-bottom: 0.6rem; // to cover for the 0.2rem margin that all radio buttons have
		margin-bottom: 0.8rem;

		@include respond-to("small") {
			padding: get-spacing("s");
		}
	}

	&.she-tree-child {
		@include questionnaire-container-spacing; // supplies 0.8rem padding all over
		padding-bottom: 0;
		padding-top: 0;

		@include respond-to("small") {
			@include input-spacing;
		}
	}
}

.she-tree-arrow {
	@include transition-default;
	color: $color-gray-dark;

	&.she-tree-arrow-collapsed {
		transform: rotateZ(-90deg);

		@at-root [dir="rtl"] & {
			transform: rotateZ(90deg);
		}
	}
}
