@import "../../styles/utils/utils";

.errorBoundary {
	align-items: center;
	background-color: $system-colours-primary;
	color: white;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: auto;

	&__content {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		margin-top: 1rem;
		max-width: 100%;
		text-align: center;
		width: 20rem;
		max-height: 100%;

		p {
			margin-bottom: 1rem;
		}
	}

	&__content-inner {
		align-self: center;
	}

	&__heading {
		font-size: 1.2rem;
		margin-bottom: 2rem;
	}

	&__figure {
		display: block;
		margin-bottom: 2rem;
		max-width: 100%;
		width: 100%;
		svg {
			width: 100%;
		}
	}

	&__image {
		width: 100%;
	}

	.she-btn {
		width: 100%;
	}

	.collapse {
		padding-top: 10px;

		a {
			width: 20rem;
			display: block;
			background-color: $color-button-secondary-background;
			border-bottom: get-spacing("xxxs") solid $color-button-secondary-border-bottom;
			color: $color-button-secondary-text;
			@include button-spacing;
			width: 80%;
			margin: auto;
		}

		div {
			display: none;
		}

		div:target {
			display: block;
		}
	}

	.errorDetails {
		padding-top: 10px;
		font-size: small;
		width: 100%;
		text-align: left;
	}

	.she-error__button-ok {
		display: block;
	}
}
