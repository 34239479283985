@import "../../../../../styles/utils/utils";

.noteDialog {
	display: flex;
	flex-direction: column;
	background-color: $color-white;
	height: 100%;
	width: 100%;

	@include respond-to("small") {
		height: auto;
		max-width: 32rem;
		max-height: 95vh;
	}

	.buttonbar {
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: get-spacing(s);

		> button {
			width: 50%;
			&:disabled {
				background-color: $color-gray-dark;
			}
		}
	}
}
