@import "../../ui/styles/utils/utils";

// Button
@mixin she-btn-bottom-line($background-color: $color-black) {
	&:before {
		@include transition-default("background-color");
		background-color: $background-color;
		content: "";
		display: block;
		position: absolute;
		bottom: -0.1rem;
		left: -0.1rem;
		right: -0.1rem;
		height: 0.1rem;
	}
}

.she-btn {
	border: get-spacing("xxxs") solid transparent;
	cursor: pointer;
	transition: all 0.15s ease-out;
	outline: 0.15rem solid transparent;
	outline-offset: -(get-spacing("xxxs"));
	border-radius: 0;
	position: relative;
	flex-basis: 50%;
	user-select: none;

	@include button-spacing;
	@include font-body-normal;

	&:hover {
		text-decoration: none;
	}

	&.she-btn-primary {
		border: solid 0.1rem $color-button-primary-background;

		background-color: $color-button-primary-background;
		color: $system-colours-primary-button-text;

		&:hover {
			border-color: $navigation-colours-background;

			background-color: $navigation-colours-background;
		}

		&:focus {
			border-color: $navigation-colours-background-hover;

			color: $color-default-system-colours-primary-button-text;
			outline: none;
		}
	}

	&.she-btn-rounded {
		padding: 20px;
		border-radius: 50%;
		background-color: $color-button-primary-background;
		color: $color-button-primary-text;
	}

	&.she-btn-secondary {
		box-sizing: border-box;
		border: get-spacing("xxxs") solid currentColor;

		background-color: $system-colours-background;
		color: $color-button-secondary-text;

		&:hover {
			color: $navigation-colours-background;
		}

		&:focus {
			outline: solid 0.1rem $navigation-colours-background-hover;

			background-color: transparent;
			box-shadow: inset 0 0 0 0.1rem $system-colours-primary;
		}
	}

	&.she-btn-danger {
		color: $color-button-primary-text;
		border-bottom: get-spacing("xxxs") solid $color-button-primary-border-bottom;
		background-color: $color-control-border-error;

		&:focus {
			color: $color-white;
			background-color: $color-control-border-error;
			outline-color: $color-black;
		}
	}

	&.she-btn-tertiary {
		@include font-guidance-normal;
		@include button-spacing;

		flex-basis: auto;

		background-color: transparent;
		color: $system-colours-primary;

		&:hover {
			color: $system-colours-primary;
		}

		&:focus,
		&:active,
		&:visited {
			color: $system-colours-primary-hover;
		}
	}

	&.she-btn-limited {
		color: $color-gray-dark;

		&:hover {
			border-color: $color-gray-dark;
			color: $color-gray-dark;
		}
	}

	&:focus {
		background-color: $color-link-focus;
		outline: 0.15rem solid $color-link;
		color: $color-button-focus-text;

		&:before {
			background-color: transparent;
		}
	}

	&:hover {
		border: get-spacing("xxxs") solid $color-link-focus;

		&:before {
			background-color: transparent;
		}
	}
}

.she-btn-busy {
	&:after {
		background-color: $color-white;
		content: "";
		display: block;
		position: absolute;
		bottom: -0.1rem;
		left: 0;
		right: 100%;
		height: 0.15rem;
		animation-name: slide;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}
}

@keyframes slide {
	0% {
		left: -0.1rem;
		right: calc(100% + 0.1rem);
	}

	33% {
		left: -0.1rem;
	}

	50% {
		right: -0.1rem;
	}

	67% {
		left: calc(100% + 0.1rem);
	}

	100% {
		left: calc(100% + 0.1rem);
		right: -0.1rem;
	}
}

// Button bar at the bottom of the page
.she-form-controls {
	bottom: 0;
	left: 0;
	width: 100%;
	position: static;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	z-index: get-z-index("form-controls");
	pointer-events: auto;

	@media (min-height: map-get($height-breakpoints, "x-small")) {
		position: fixed;
		width: 100%;
	}

	@include respond-to("medium") {
		pointer-events: none;
	}

	.she-btn {
		flex-grow: 1;
		flex-basis: 50%;
		pointer-events: auto;

		&.she-btn-secondary {
			background-color: $system-colours-background;
		}
	}
}

.she-components-button-bar {
	width: 100%;
	max-width: 48.5rem;
	display: flex;
	margin: auto;

	@include respond-to("small") {
		@media (min-height: map-get($height-breakpoints, "x-small")) {
			&:before {
				display: block;
				content: "";
				width: 8rem;
				flex-grow: 0;
				flex-shrink: 0;

				@include respond-to("medium") {
					width: 10rem;
				}

				@include respond-to("x-large") {
					width: 12rem;
				}
			}
		}
	}
}

.she-icon {
	padding: get-spacing("xs");

	&.she-icon-gray {
		color: $color-gray-dark;
	}

	svg {
		width: 1.2rem;
		height: 1.2rem;
		@include transition-default;
	}
}

.she-questionnaire-section-header {
	@include questionnaire-item-spacing;

	&:focus {
		outline: none;
	}
}
