@import "../../../../styles/utils/utils";

.she-page-language-switcher {
	padding-top: 10px;
	margin-bottom: 0;
	color: $color-white;

	.she-language-container {
		border-bottom: 1.5px solid grey;
		padding: 5px 20px;

		&__label {
			color: black;
		}
	}
}

.she-language-container {
	margin-bottom: 0.55rem;

	&__text {
		display: flex;
		flex-direction: column;
		margin-top: -0.55rem;
	}

	&__title {
		font-size: 1rem;
		line-height: 1.2rem;
		font-weight: 500;
		color: black;
	}

	&__label {
		font-size: 0.8rem;
		line-height: 1.2rem;
		font-weight: normal;
	}
}
