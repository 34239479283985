.minimap__label {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	bottom: 0.2rem;
	z-index: 401;
	font-size: 0.7rem;
	left: 0.2rem;
	right: 0.2rem;
	border-radius: 4px;
	text-align: center;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
