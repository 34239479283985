@import "../../../styles/utils/utils";

.she-error-message-container {
	@include layout-flex-center;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	color: $color-gray-dark;

	> * {
		text-align: center;
	}
}
