@import "../../../../styles/utils/utils";

.she-queued-record-submission-failure {
	color: $color-danger;
	@include font-guidance-normal;

	width: 100%;
	align-items: center;
	text-align: inherit;
	display: flex;
	align-items: center;

	&:before {
		@include validation-mark;
		margin-inline-end: get-spacing("xxs");
	}
}
