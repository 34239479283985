@import "../../../styles/utils/utils";

.she-breadcrumbs-container {
	@include font-guidance-normal;

	&.she-breadcrumbs-container-empty {
		padding: 0;
	}

	.she-breadcrumbs__button {
		@include font-guidance-normal;
		padding: 0.6rem 0;
		display: inline;
		border: get-spacing("xxxs") solid transparent;
		cursor: pointer;
		transition: all 0.15s ease-out;
		outline: get-spacing("xxxs") solid transparent;
		outline-offset: -(get-spacing("xxxs"));
		border-radius: 0;
		background-color: transparent;
		color: $system-colours-primary;

		&:focus {
			outline: 0.15rem solid $color-link;
		}

		&:hover {
			outline: get-spacing("xxxs") solid $color-link-focus;
			color: $system-colours-primary-hover;
		}
	}

	.she-breadcrumbs-current {
		@include font-guidance-bold;
		padding: 0.6rem 0;
	}
}
