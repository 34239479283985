@import "../../../styles/utils/utils";

.she-progressBar {
	display: flex;
	align-items: center;

	position: relative;

	overflow: hidden;
	width: 100%;
	height: 24px;
	border-radius: 360px;

	background-color: $color-gray-light;

	&__completed {
		position: relative;
		transform: translateX(-100%);

		width: 100%;
		height: 24px;
		border-radius: 360px;

		background-color: $color-success;

		&-label {
			@include font-progress-normal;

			display: block;
			text-align: center;

			position: absolute;

			width: 100%;

			&.she-progressBar__completed-label--invert-color {
				color: $color-white;
			}
		}
	}
}
