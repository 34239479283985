@import "../../styles/utils/utils";

.she-menu {
	display: inline-block;

	position: relative;

	min-width: 0;

	.she-menu__list {
		@include transition-default;
		@include questionnaire-item-subitem-margin;

		position: absolute;
		top: 0;
		right: 0;
		z-index: get-z-index("form-popup");

		width: max-content;
		max-width: 90vw;
		margin: 0;
		padding: 0;
		border: $border-width solid $color-control-border-normal;

		background: $color-white;

		list-style: none;

		[dir="rtl"] & {
			left: 0;
			right: auto;
		}
	}

	.she-menu__item {
		@include button-spacing;
		@include input-focus-hover;
		@include transition-default;

		display: flex;
		align-items: center;

		border-color: transparent;
		color: $color-text-primary;

		cursor: pointer;

		&:hover {
			border-color: transparent;
			background-color: $system-colours-primary;
			color: $navigation-colours-text-icon-hover;
		}

		.she-menu__item__icon {
			margin: #{- (get-spacing("xxs"))} #{get-spacing("s")} #{- (get-spacing("xxs"))} 0;

			[dir="rtl"] & {
				margin: #{- (get-spacing("xxs"))} 0 #{- (get-spacing("xxs"))} #{get-spacing("s")};
			}

			&.she-menu__item__icon--conditional {
				visibility: hidden;
			}

			svg {
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		&.she-menu__item--selected .she-menu__item__icon--conditional {
			visibility: visible;
		}
	}

	.she-menu__trigger {
		@include transition-default(("outline", "border"));
		@include questionnaire-item-subitem-margin;

		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		position: relative;

		width: 100%;
		margin-bottom: 0;
		padding: get-spacing("xs");
		border: $border-width solid transparent;
		outline: $outline-width solid transparent;
		outline-offset: $outline-offset;

		color: inherit;

		appearance: none;

		&:focus {
			outline-color: $color-control-border-hover;
		}

		&:hover {
			.she-menu__trigger__chevron {
				color: $color-control-border-hover;
			}
		}

		.she-menu__trigger__label {
			overflow: hidden;
			text-overflow: ellipsis;

			margin: #{- (get-spacing("xxs"))} get-spacing("xs") #{- (get-spacing("xxs"))} #{0};

			white-space: nowrap;

			[dir="rtl"] & {
				margin: #{- (get-spacing("xxs"))} #{0} #{- (get-spacing("xxs"))} get-spacing("xs");
			}

			@include respond-to("small") {
				margin: #{- (get-spacing("xxs"))} get-spacing("xs") #{- (get-spacing("xxs"))}
					get-spacing("xs");

				[dir="rtl"] & {
					margin: #{- (get-spacing("xxs"))} get-spacing("xs") #{- (get-spacing("xxs"))}
						get-spacing("xs");
				}
			}
		}

		.she-menu__trigger__chevron {
			padding: 0;

			color: $color-gray-dark;

			svg {
				width: 1.2rem;
				height: 1.2rem;
			}
		}

		&.she-menu__trigger--select {
			@include input-focus-hover;

			background: $color-white;
			color: $color-text-primary;
		}
	}
}
