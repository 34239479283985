.she-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__container {
		flex-basis: 30%;
		display: flex;
		justify-content: center;

		&:first-child {
			justify-content: flex-start;
		}

		&:last-child {
			justify-content: flex-end;
		}
	}

	.she-btn {
		flex-basis: auto;
	}
}
