@import "src/ui/styles/utils/utils";

.she-records {
	display: flex;
	flex-direction: column;

	&__list {
		display: flex;
		margin: 0 -0.6rem;
		padding: 0;
		list-style: none;
		align-content: flex-start;
		flex-flow: row wrap;
	}

	&__wrapper {
		padding: get-spacing("xs") 0;
	}

	&__list-item {
		width: 100%;
		overflow: hidden;
		display: flex;
		align-items: stretch;
		position: relative;
		padding: 0.4rem;
		flex-shrink: 0;

		@include respond-to("medium") {
			padding: 0.6rem;
		}

		@include respond-to("x-large") {
			width: 50%;
			flex-grow: 0;
		}
	}

	&__status {
		padding-top: get-spacing("s");
	}

	&__error {
		padding-top: get-spacing("s");
		word-wrap: break-word;
	}

	&__details {
		@include font-guidance-normal;
		color: $color-text-secondary;
		text-align: right;
	}
}

.she-records__sort {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	padding-top: get-spacing("xs");

	.she-records__sort__label {
		display: inline-block;

		margin-inline-end: get-spacing("s");

		color: $color-text-secondary;
	}
}
