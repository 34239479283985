@import "../../../../styles/utils/utils";

.she-start-questionnaire-modal {
	display: flex;
	align-items: flex-start;

	.she-start-questionnaire-modal-body {
		padding: 0;
		padding-inline-start: get-spacing("s");
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		flex-grow: 1;

		.she-start-questionnaire-modal-body-title {
			@include font-body-bold;
			margin-bottom: get-spacing("xs");
			word-break: break-word;
			word-wrap: break-word;
			width: 100%;
		}

		.she-start-questionnaire-modal-body-description {
			@include font-guidance-normal;
			margin-bottom: get-spacing("xs");
			word-break: break-word;
			word-wrap: break-word;
			width: 100%;
		}

		.she-start-questionnaire-modal-body-data {
			@include font-guidance-normal;
			color: $color-text-hint;
		}
	}
}
