// File: animations.scss
// Animation and transition variables and keyframes

$transition-default-duration: 0.15s;
$transition-long-duration: 0.25s;
$transition-very-long-duration: 1s;
$transition-default-easing: ease-out;

$transition-modal-miliseconds: 250;
$transition-modal-duration: $transition-modal-miliseconds + 0ms;

:export {
	transitionModalDuration: $transition-modal-miliseconds;
}

@mixin transition-default(
	$elements: (
		"all",
	)
) {
	$all: ();
	@each $e in $elements {
		$all: append(
			$all,
			$transition-default-duration unquote($e) $transition-default-easing,
			comma
		);
	}
	transition: $all;
}

@mixin transition-long(
	$elements: (
		"all",
	)
) {
	$all: ();
	@each $e in $elements {
		$all: append($all, $transition-long-duration unquote($e) $transition-default-easing, comma);
	}
	transition: $all;
}
