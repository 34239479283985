@import "../../../styles/utils/utils";

.she-org-unit-select {
	.she-org-unit-select__box {
		@include input-focus-hover;
		@include questionnaire-item-subitem-margin;
		@include transition-default(("outline", "border"));
		display: flex;
		width: 100%;
		position: relative;
		justify-content: stretch;
		background-color: $color-white;
		flex-direction: column;
		min-height: 2.5rem;
		padding: get-spacing("xs") (get-spacing("xs") + $control-height) get-spacing("xs")
			get-spacing("xs");
		cursor: pointer;

		[dir="rtl"] & {
			padding: get-spacing("xs") get-spacing("xs") get-spacing("xs")
				(get-spacing("xs") + $control-height);
		}

		.she-org-unit-select__box__icon {
			height: auto;
		}

		&.she-org-unit-select__box--invalid {
			@include input-focus-hover-invalid;
		}

		&.she-org-unit-select__box--fixed {
			@include input-focus-disabled;
			cursor: initial;
			background-color: transparent;
		}
	}
}

.she-components-radio-group-button {
	.she-breadcrumbs-container-empty {
		padding-top: 0.55rem;
	}
}

.she-btn-orgunit-navigate {
	margin-inline-start: 2.5rem;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	svg {
		height: 1rem;
		width: 1rem;
	}

	span {
		padding-inline-start: 0.5rem;
	}
}

.she-org-unit-select__breadcrumbs {
	@include questionnaire-container-spacing;
	border-bottom: $color-gray-dark 0.1rem solid;
}

.she-org-unit-select__searchbox {
	padding: get-spacing("s") get-spacing("s") 0 get-spacing("s");

	@include respond-to("small") {
		padding: get-spacing("ml") get-spacing("ml") 0 get-spacing("ml");
	}
}

.she-org-unit-select__search-results {
	.she-org-unit-select__search-results__item {
		padding: get-spacing("xs") get-spacing("s") calc(#{get-spacing("xs")} - 0.2rem)
			get-spacing("s");
		border-top: $border-width solid $color-control-border-normal;

		@include respond-to("small") {
			padding: get-spacing("xs") get-spacing("ml") calc(#{get-spacing("xs")} - 0.2rem)
				get-spacing("ml");
		}

		label {
			padding-top: 0;
		}
	}
}

.she-org-unit-select__search-results__guidance {
	@include font-guidance-normal;
	padding: get-spacing("xs") get-spacing("s");
	color: $color-text-secondary;
	margin: 0;

	@include respond-to("small") {
		padding: get-spacing("xs") get-spacing("ml");
	}
}

.she-org-unit-select__search-results__empty {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 60vh;
	color: $color-gray-light;

	text-align: center;
}
