@import "../../styles/utils/utils";

.optional-register-select__container {
	.optional-register-select__radio-option {
		display: flex;
		align-items: center;

		.optional-register-select__radio-option-description {
			font-size: $font-size-body;
		}
	}

	.optional-register-select-option__content {
		margin-inline-start: get-spacing("l");
	}
}
