@import "../../../../styles/utils/utils";

.she-upload-error-wrapper {
	.she-upload-error-form {
		@include transition-default;
		@include questionnaire-item-subitem-margin;
		@include transition-default(("outline", "border"));
		width: 100%;
		padding: 1rem;
		border: 0.1rem solid $color-white;
		margin-bottom: get-spacing("s");
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}

	.she-error-questionnaire-title {
		@include font-body-normal;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: get-spacing("xxs");
	}

	.she-error-questionnaire-data {
		@include font-guidance-normal;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		margin-bottom: get-spacing("xxs");
	}

	.she-record-submission-failure {
		color: $color-white;
		@include font-guidance-normal;

		width: 100%;
		align-items: center;
		text-align: inherit;
		display: flex;
		align-items: flex-start;

		&:before {
			background-color: $color-white;
			border-radius: 50%;
			color: $system-colours-primary;
			content: "!";
			flex-grow: 0;
			flex-shrink: 0;
			display: block;
			text-align: center;
			height: 1rem;
			width: 1rem;
			line-height: 1rem;
			font-weight: bold;
			margin-top: 0.1rem;

			margin-inline-end: get-spacing("xxs");
		}
	}
}

.she-record-submission-failure-subtitle {
	text-align: center;
}
