@import "../../../../../styles/utils/utils";

.she-ppe-tile {
	flex-direction: column;

	.she-ppe-submodule-item {
		@include font-guidance-normal;
		flex-grow: 1;
		display: flex;
		width: 100%;
		justify-content: flex-start;
		padding: get-spacing("xxs") 0;

		.she-ppe-submodule-item__category {
			font-weight: $font-weight-bold;
			word-break: break-word;
		}

		.she-ppe-submodule-item__option {
			@include questionnaire-item-subitem-margin;
			word-break: break-word;
			&:last-child {
				margin-bottom: 0;
			}
		}

		svg {
			width: 3rem;
			height: 3rem;
			flex-grow: 0;
			flex-shrink: 0;
			margin-inline-end: get-spacing("xs");
		}
	}
}
