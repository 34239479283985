.she-components-validation-summary {
	border-style: solid;
	border-width: 0.15rem;
	border-color: $color-danger;
	list-style: none;
	padding: get-spacing("xs");
	margin-inline-start: -0.6rem;
	margin-inline-end: -0.6rem;
	padding-inline-start: 0.6rem;
	padding-inline-end: 0.6rem;

	.she-components-validation-summary-header {
		@include font-body-bold;
		padding-bottom: get-spacing("xs");
	}
}

.she-components-validation-message {
	@include font-hint-bold;
	@include questionnaire-item-subitem-margin;
	color: $color-text-error;
	display: flex;

	&:before {
		@include validation-mark;
		margin-inline-end: get-spacing("xxs");

		@at-root [dir="rtl"] & {
			margin-inline-start: get-spacing("xxs");
			margin-inline-end: get-spacing("xxs");
		}
	}
}

.she-components-validation-border-left {
	&:before {
		content: "";
		width: 0.15rem;
		position: absolute;
		top: 0.2rem;
		left: -0.6rem;
		bottom: 0.2rem;
		display: block;
		border-left: 0.15rem solid #d0021b;
	}
}
