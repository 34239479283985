@import "../../../../styles/utils/utils";

$calendar-size: 1rem;

.she-action-card {
	width: 100%;
	cursor: pointer;
	padding: get-spacing("s");
	position: relative;
	list-style-type: none;

	&__clickable {
		transition: $transition-default-duration $transition-default-easing,
			box-shadow $transition-default-duration $transition-default-easing,
			background-color $transition-very-long-duration ease-out;
		background-color: $color-gray-lightest;
		background: transparent;
		box-shadow: $shadow-default;
		display: flex;
		flex-direction: row;
		outline: $outline-width solid transparent;
		text-decoration: none;
		color: $color-text-primary;
		outline-offset: 0;

		&:hover {
			background: $color-white;
			box-shadow: $shadow-strong;
			color: $color-text-primary;
		}

		&:focus {
			outline-color: $color-control-border-focus;
			color: $color-text-primary;
		}
	}

	&__header {
		display: flex;
		margin-bottom: get-spacing("xs");
		justify-content: space-between;
		width: 100%;
	}

	&__header-chips > * {
		margin-inline-end: get-spacing("xs");

		span {
			text-transform: uppercase;
		}
	}

	&__header-ref {
		font-size: $font-size-hint;

		color: $color-text-secondary;
	}

	&__body {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__body-description {
		@include font-body-bold;
		font-size: $font-size-screen-small;
		max-width: 70%;

		p {
			margin: 0px;
		}
	}

	&__body-menu > button {
		&:focus {
			outline-color: $color-control-border-focus;
			color: $color-text-primary;
		}
	}

	&__date {
		font-size: $font-size-hint;
		display: flex;
		align-items: center;

		p {
			margin: 0px;
		}

		&-calendar {
			margin: get-spacing("xs");
			margin-inline-start: 0px;
			color: $color-gray-dark;
			flex-shrink: 0;
			height: $calendar-size;
			width: $calendar-size;
			overflow: hidden;

			svg {
				@include transition-long;
			}
		}
	}

	&__footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: $font-size-hint;
	}

	&__footer-owner {
		display: column;
		@include respond-to("small") {
			display: flex;
		}
		margin-bottom: get-spacing("s");

		span {
			@include font-hint-bold;
			color: $color-gray-dark;
			margin-inline-end: get-spacing("xs");
		}

		p {
			margin: 0px;
			margin-inline-end: get-spacing("s");
		}
	}

	&__footer-progress {
		width: 100%;
		@include respond-to("large") {
			width: 200px;
		}
	}
}
