@import "../../../styles/utils/utils";

.she-callapsible-list {
	margin: 0;
	padding: 0;
	list-style: none;
	padding-inline-end: 1.8rem;

	li {
		@include font-guidance-normal;
		margin: 0;
		padding: 0;
	}

	&__key {
		font-weight: $font-weight-bold;
	}

	&__accent {
		font-weight: $font-weight-bold;
		font-size: $font-size-body;
	}

	&__value {
		@include questionnaire-item-subitem-margin;
	}

	&__toggle {
		position: absolute;
		right: 0;
		top: 0;

		[dir-rtl] & {
			right: auto;
			left: 0;
		}
	}
}
