@import "../../../../styles/utils/utils";

.she-action-field__label {
	padding-top: get-spacing("xs");
}

.she-action-field__value {
	padding-bottom: get-spacing("xs");
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-height: $line-height-body;
	line-height: $line-height-body;

	&--column {
		flex-direction: column;
		place-items: flex-start;
	}

	margin-inline-start: 0;
}
