.she-components-submodule {
	position: relative;

	.she-components-submodule-container {
		@include questionnaire-item-spacing;
		display: flex;
		flex-direction: column;

		> label {
			@include questionnaire-item-subitem-margin;
			@include font-body-bold;
			display: block;
		}
	}

	.she-components-submodule-iq {
		padding: 0 0;
	}
}

.she-components-submodule-matrix {
	display: flex;
}

.she-submodule-matrix-text {
	margin: 0 get-spacing("xs");
	margin-top: 0.15rem;
}

.she-components-submodule-tile {
	@include input-spacing;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
	display: flex;
	width: 100%;
	cursor: pointer;
	position: relative;
	justify-content: stretch;
	background-color: $color-white;

	&[aria-invalid="true"] {
		@include input-focus-hover-invalid;
	}

	&:focus {
		border: $border-width solid $color-radio-border-focus;
	}

	button {
		@include font-guidance-normal;
		color: $color-link;
		align-self: flex-start;

		&:focus {
			outline: 0;
		}
	}

	dl {
		@include font-guidance-normal;
		margin-inline-end: 1.5rem;
		flex-grow: 1;

		dt {
			font-weight: $font-weight-bold;
			word-break: break-word;
		}

		dd {
			@include questionnaire-item-subitem-margin;
			word-break: break-word;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.she-components-submodule-remove {
		position: absolute;
		top: get-spacing("xxxs");
		right: get-spacing("xxxs");
		z-index: 1;

		[dir="rtl"] & {
			right: auto;
			left: get-spacing("xxxs");
		}

		button {
			svg {
				@include transition-default;
				color: $color-gray-dark;
			}

			&:hover {
				svg {
					color: $color-link-focus;
				}
			}

			&:focus {
				border: $border-width solid $color-radio-border-focus;
			}
		}
	}

	.she-components-submodule-stats {
		display: flex;
		align-items: center;

		.she-icon {
			padding-inline-start: 0;
		}
	}
}
