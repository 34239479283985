@import "../../styles/utils/utils";

.she-portal-footer-version {
	text-align: center;
}

.she-version-label {
	@include font-hint-normal;
	@include questionnaire-item-subitem-margin;
	color: $color-text-hint;
}
