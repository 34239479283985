@import "../../styles/utils/utils";

$className: "she-module-card";
$card-height-small: 6rem;
$card-height-large: 7rem;

.#{$className} {
	@include transition-default;
	align-items: stretch;
	box-shadow: $shadow-default;
	display: flex;
	width: 100%;
	height: $card-height-small;

	@include respond-to("medium") {
		height: $card-height-large;
	}

	&--as-button {
		border-radius: 0;
		color: $color-black;
		padding: 0;

		&:hover {
			background-color: white;
			cursor: pointer;
			user-select: none;
			box-shadow: $shadow-strong;
			color: $color-black;
		}

		&:focus {
			color: $color-black;
			outline-offset: 0;
			outline: $system-colours-primary solid 3px;
		}
	}

	&__body {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex: 1;
		overflow: hidden;
		padding: get-spacing("xs") get-spacing("s");
	}

	&__title {
		@include font-body-bold;
		margin: 0;
		overflow: hidden;
		word-wrap: break-word;
	}

	&__thumbnail {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		overflow: hidden;
		width: $card-height-small;
		height: $card-height-small;

		background-color: $color-white;

		@include respond-to("medium") {
			height: $card-height-large;
			width: $card-height-large;
		}

		svg {
			@include transition-long;
			width: 60%;
		}
	}

	&__thumbnail-image {
		@include transition-long;
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 100%;
	}
}
