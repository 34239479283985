@import "../../styles/utils/utils";

.she-thumbnail-list-image {
	margin: 0.4rem;
	box-sizing: content-box;
	@include transition-default;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	background-color: $color-white;

	img {
		object-fit: cover;
		height: 3rem;
		width: 3rem;
		vertical-align: middle;
		display: inline-block;
	}

	.she-thumbnail-image {
		background: $color-white;
		align-items: center;
		display: flex;
		height: 100%;
	}

	.she-thumbnail-expanding-icon {
		width: 3rem;
		min-height: 3rem;
		overflow: hidden;
	}

	.she-thumbnail-text-wrapper {
		display: flex;
		flex-flow: column;
	}

	.she-thumbnail-more-options {
		button {
			svg {
				height: 2rem;
				@include transition-default;
				color: $color-gray-dark;
			}

			&:hover {
				svg {
					color: $color-link-focus;
				}
			}
		}

		position: absolute;
		top: get-spacing("xxxs");
		right: get-spacing("xxxs");

		[dir="rtl"] & {
			right: auto;
			left: get-spacing("xxxs");
		}
	}
}

.she-thumbnail-clickable {
	cursor: pointer;
}

.she-thumbnail-options-wrapper {
	@include transition-default;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
	top: 0;
	right: 0;
	display: none;
	position: absolute;
	background: $color-white;
	z-index: 100;

	[dir="rtl"] & {
		right: auto;
		left: 0;
	}

	.she-thumbnail-options {
		.she-thumbnail-option {
			width: 100%;
			padding: 0;

			&:hover {
				background-color: $color-gray-light;
				color: $color-text-primary;
			}

			button {
				@include button-spacing;
				width: 100%;
				display: flex;
				align-items: center;
			}

			div {
				span {
					vertical-align: middle;
					color: black;
				}
			}
		}
	}

	svg {
		height: 1rem;
		width: 1rem;
		display: inline-block;
		margin: get-spacing("xs");
		color: $color-black;
	}
}

.she-thumbnail-options-overlay {
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	z-index: 80;
}

.she-thumbnail-filename {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	margin-inline-end: 2rem;
	padding: 0.4rem 2.4rem 0.2rem 0.4rem;
	max-height: 8.3rem;
	width: 100%;
	height: fit-content;
	overflow-y: auto;
	color: $color-gray-dark;

	[dir="rtl"] & {
		padding: 0.4rem 0.4rem 0.2rem 2.4rem;
	}
}

.she-thumbnail-description {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	margin-inline-end: 2rem;
	padding: 0 2.4rem 0.4rem 0.4rem;
	max-height: 8.3rem;
	width: 100%;
	height: fit-content;
	overflow-y: auto;

	[dir="rtl"] & {
		padding: 0rem 0.4rem 0.2rem 2.4rem;
	}
}

.showOptions {
	display: block;
}
