@import "src/ui/styles/utils/utils";

.action-last-comment {
	width: 100%;
	margin-top: get-spacing("s");

	&__header {
		display: flex;
		align-items: center;
	}

	&__title {
		flex-grow: 1;
		font-weight: 500;
	}

	&__tag {
		padding: get-spacing("xxs") get-spacing("xs");
		border-radius: 0.2rem;
		color: $color-white;
		font-size: 0.6rem;
		font-weight: 700;
		text-transform: uppercase;

		&--edited {
			background-color: $color-warning;
		}

		&--deleted {
			background-color: $color-danger;
		}
	}

	&__date {
		font-weight: 500;
		font-size: 0.8rem;
	}

	&__status {
		font-size: 0.8rem;
	}

	&__description {
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-top: get-spacing("xs");
		font-size: 1rem;
		white-space: pre-wrap;
		word-break: break-word;
	}

	&__view-full {
		display: inline-block;
		margin-top: get-spacing("xs");
	}
}
