@import "../../../../styles/utils/utils";

.she-mock-sidebar {
	display: none;

	@include respond-to("small") {
		width: 8rem;
		min-height: calc(100vh);
		display: flex;
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		background-color: $color-white;
		box-shadow: $shadow-default;

		[dir="rtl"] & {
			left: auto;
			right: 0;
		}

		@include respond-to("medium") {
			width: 10rem;
		}

		@include respond-to("x-large") {
			width: 12rem;
		}
	}
}
