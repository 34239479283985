@import "../../../../styles/utils/utils";

.she-main-nav {
	&__button {
		@include font-guidance-normal;
		@include button-spacing;
		border: get-spacing("xxxs") solid transparent;
		background-color: transparent;
		color: $navigation-colours-text-icon;

		&:hover,
		&:focus {
			outline: 0.1rem solid $navigation-colours-background-hover;

			background-color: transparent;
			color: $navigation-colours-background-hover;

			.she-main-nav__icon-settings {
				color: $navigation-colours-background-hover;
			}
		}

		&:focus {
			outline-width: 0.15rem;
		}
	}

	&__icon-settings {
		width: 24px;
		color: $navigation-colours-text-icon;
	}
}
