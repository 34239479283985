@import "../../../styles/utils/utils";

.she-project-list {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	overflow: hidden;
	height: 100%;

	&__search {
		@include respond-to("small") {
			padding: get-spacing("ml") get-spacing("ml") get-spacing("s");
		}
		padding: get-spacing("s");
	}

	&__list {
		flex-grow: 2;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	&__pagination {
		@include respond-to("small") {
			padding: get-spacing("xs") get-spacing("ml");
		}
		padding: get-spacing("xs") get-spacing("s");
	}

	&__numbers {
		@include font-guidance-normal;
		@include respond-to("small") {
			padding: get-spacing("xs") get-spacing("ml");
		}
		padding: get-spacing("xs") get-spacing("s");
		color: $color-text-secondary;
		border-top: $border-width solid $color-control-border-normal;

		&:first-of-type {
			border-top: none;
		}
	}

	&__status {
		@include font-h2;
		padding: get-spacing("s");
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		color: $color-gray-dark;
		text-align: center;
	}

	&__sort {
		@include respond-to("small") {
			padding: 0 get-spacing("ml") get-spacing("s");
		}

		padding: 0 get-spacing("s") get-spacing("s");

		&-button {
			@include button-spacing;
			white-space: nowrap;
			display: flex;
			align-items: center;
			@include transition-default;

			svg {
				width: 1.2rem;
				height: 1.2rem;
				display: inline-block;
				margin-inline-end: get-spacing("xs");
			}
		}
	}
}
