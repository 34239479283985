// File: _colors.scss
// This file contains all colors used in the application

// Basic colors
$color-black: #0b0b0b;
$color-black-transparent: rgba(0, 0, 0, 0.5);
$color-black-shadow: rgba(0, 0, 0, 0.16);
$color-black-shadow-strong: rgba(0, 0, 0, 0.24);
$color-gray-dark: #687073;
$color-gray-light: #dee0e2;
$color-gray-lighter: #f2f2f4;
$color-gray-lightest: #f8f8f8;
$color-white: #fff;

// Semantic
$color-disabled: #9ca3af;
$color-warning: #ffbe4f;
$color-success: #01903d;
$color-danger: #cb2f00;

// Defaults
$color-default-system-colours-text: #212529;
$color-default-system-colours-background: #f1f3f5;
$color-default-system-colours-primary: #1783fb;
$color-default-system-colours-primary-button-text: #ffffff;
$color-default-system-colours-primary-hover: #136dd2;

$color-default-navigation-colours-background: #00336e;
$color-default-navigation-colours-background-hover: #54769e;
$color-default-navigation-colours-text-icon: #ffffff;
$color-default-navigation-colours-text-icon-hover: #ffffff;

// Text
$color-text-primary: $color-black;
$color-text-secondary: $color-gray-dark;
$color-text-inverted: $color-white;
$color-text-hint: $color-gray-dark;
$color-text-error: $color-danger;
$color-text-required: $color-danger;

// Theme - System
$system-colours-text: var(--system-colours-text, $color-default-system-colours-text);
$system-colours-background: var(
	--system-colours-background,
	$color-default-system-colours-background
);
$system-colours-primary: var(--system-colours-primary, $color-default-system-colours-primary);
$system-colours-primary-button-text: var(
	--system-colours-primary-button-text,
	$color-default-system-colours-primary-button-text
);
$system-colours-primary-hover: var(
	--system-colours-primary-hover,
	$color-default-system-colours-primary-hover
);

// Theme - Navigation
$navigation-colours-background: var(
	--navigation-colours-background,
	$color-default-navigation-colours-background
);
$navigation-colours-background-hover: var(
	--navigation-colours-background-hover,
	$color-default-navigation-colours-background-hover
);
$navigation-colours-text-icon: var(
	--navigation-colours-text-icon,
	$color-default-navigation-colours-text-icon
);
$navigation-colours-text-icon-hover: var(
	--navigation-colours-text-icon-hover,
	$color-default-navigation-colours-text-icon-hover
);

// Links
$color-link: $system-colours-primary;
$color-link-hover: $system-colours-primary;
$color-link-focus: $system-colours-primary;

// Buttons
$color-button-primary-background: $system-colours-primary;
$color-button-primary-text: $color-white;
$color-button-primary-border-bottom: $color-black;

$color-button-secondary-background: $system-colours-background;
$color-button-secondary-text: $system-colours-primary;
$color-button-secondary-border: $system-colours-primary;
$color-button-secondary-border-bottom: $system-colours-primary;

$color-button-tertiary-background: $color-white;
$color-button-tertiary-text: $navigation-colours-text-icon-hover;
$color-button-tertiary-text-hover: $navigation-colours-background-hover;

$color-button-focus-background: $color-link-focus;
$color-button-focus-text: $system-colours-primary;
$color-button-focus-border: $system-colours-primary;

// Chips
$color-chip-primary-background: $color-success;
$color-chip-secondary-background: $system-colours-primary;
$color-chip-error-background: $color-danger;

// Control borders
$color-control-border-normal: $color-gray-dark;
$color-control-border-hover: $system-colours-primary;
$color-control-border-focus: $system-colours-primary;
$color-control-border-error: $color-danger;

$color-control-background: $color-white;

// area colours
$color-control-border-error: $color-danger;

// Radio
$color-radio-background: $color-white;
$color-radio-border-normal: $color-gray-dark;
$color-radio-border-hover: $system-colours-primary;
$color-radio-border-focus: $system-colours-primary;

// Shadows
$shadow-small: 0 0.1rem 0.4rem $color-black-shadow;
$shadow-default: 0 0.15rem 0.6rem $color-black-shadow;
$shadow-strong: 0 0.3rem 1rem $color-black-shadow-strong;
