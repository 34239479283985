@use "sass:math";

.she-components-modal-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: get-z-index("modal");
	opacity: 0;
	transition: opacity math.div($transition-modal-duration, 2) linear;

	&.she-components-modal-lightbox {
		background: rgba(0, 0, 0, 0.85);

		.she-components-modal-lightbox-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: flex;
			justify-content: space-between;
			height: 3rem;
			background-image: linear-gradient(
				to top,
				rgba(255, 255, 255, 0) 0%,
				rgba(0, 0, 0, 0.5) 100%
			);
		}

		.she-components-modal {
			position: static;
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			margin: 0;
			overflow: hidden;
			background: transparent;
			pointer-events: none;

			.she-components-modal-internal {
				overflow: hidden;
				align-items: center;
				display: flex;
				justify-content: center;
			}

			.she-components-modal-lightbox-icon {
				cursor: pointer;
				padding: 1rem;
				color: $color-white;
				z-index: 10000;
				pointer-events: all;

				svg {
					filter: drop-shadow(0px 0px 8px #000);
				}

				&.she-components-modal-lightbox-icon__back {
					[dir="rtl"] & {
						transform: scale(-1);
					}
				}
			}

			.she-components-modal-lightbox-image {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: calc(100% - 6.4rem);
				flex-shrink: 0;
				vertical-align: middle;
				pointer-events: all;

				@include respond-to("small") {
					max-height: calc(100% - 6.4rem);
					max-width: calc(100% - #{get-spacing("s")} * 2);
				}
			}
		}
	}
}

.she-components-modal-overlay-after-open {
	opacity: 1;
}

.she-components-modal-overlay-before-close {
	opacity: 0;
}

.she-components-modal-body {
	overflow: hidden;
}

.she-components-modal {
	position: relative;
	display: flex;
	flex-direction: row;
	background-color: $color-white;
	overflow: auto;
	width: calc(100% - #{get-spacing("s")} * 2);
	max-width: 30rem;
	max-height: 100%;
	margin: get-spacing("s");
	transform: translateY(1rem);
	transition: transform $transition-modal-duration ease-out;

	&:focus-visible {
		outline: none;
	}

	&.she-components-modal-after-open {
		transform: translateY(0rem);
	}

	&.she-components-modal-before-close {
		transform: translateY(1rem);
		pointer-events: none;
		transition: transform 10 ease-out;
	}

	&.she-components-modal-full-screen {
		height: 100%;
		width: 100%;
		margin: 0;
		transform: translateY(5rem);

		&.she-components-modal-after-open {
			transform: translateY(0rem);
		}

		&.she-components-modal-before-close {
			transform: translateY(5rem);
		}

		@include respond-to("small") {
			height: auto;
			width: calc(100% - #{get-spacing("s")} * 2);
			max-width: 40rem;
			margin: get-spacing("s");
			transform: translateY(1rem);

			&.she-components-modal-after-open {
				transform: translateY(0rem);
			}

			&.she-components-modal-before-close {
				transform: translateY(1rem);
			}
		}
	}

	&.she-components-modal-illustrated {
		@include respond-to("small") {
			max-width: 30rem;
		}

		.she-components-modal-body-illustrated {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			padding: get-spacing("ml") get-spacing("ml") get-spacing("s") get-spacing("ml");
			justify-content: center;
			overflow: auto;

			@include respond-to("small") {
				padding-bottom: get-spacing("ml");
			}

			.she-components-modal-body-illustrated-content {
				display: flex;
				flex-direction: column;
				max-height: 100%;

				.she-components-modal-illustration {
					padding-top: get-spacing("s");

					svg {
						margin: auto;
						width: 100%;
						max-width: 16rem;
						max-height: 16rem;
					}
				}
			}
		}
	}

	&.she-components-modal-full-height {
		height: 100%;

		@include respond-to("small") {
			height: 100%;
		}
	}

	&.she-components-modal-flexible-size {
		height: 100%;
		width: 100%;
		overflow: hidden;

		@include respond-to("small") {
			height: auto;
			width: auto;
			max-height: calc(100% - #{get-spacing("s")} * 2);
			max-width: calc(100% - #{get-spacing("s")} * 2);
		}

		.she-components-modal-internal {
			overflow: hidden;
		}
	}

	.she-components-modal-body-padded {
		@include questionnaire-container-spacing;
	}

	.she-components-modal-body-centred {
		text-align: center;
	}

	.she-components-modal-body-title {
		font-weight: $font-weight-bold;
		padding-bottom: 0;
		font-size: $font-size-h3;
	}

	.she-components-modal-body-prewrap {
		white-space: pre-wrap;
	}

	.she-components-modal-body-form {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.she-components-modal-body-notification {
		height: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.she-components-modal-internal {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: auto;

		.she-components-modal-header {
			display: flex;
			justify-content: stretch;
			align-items: center;
			flex-shrink: 0;

			background-color: $navigation-colours-background;
			color: $navigation-colours-text-icon;

			h3 {
				margin: 0;
				padding: get-spacing("s") get-spacing("s");

				@include respond-to("small") {
					padding: get-spacing("s") get-spacing("ml");
				}
			}
		}

		.she-components-modal-footer {
			margin-top: auto;
			display: flex;
			flex-shrink: 0;

			&.padded {
				padding: 0 get-spacing("ml") get-spacing("s") get-spacing("ml");
				flex-wrap: wrap;

				button {
					margin-bottom: get-spacing("s");
					flex-basis: 100%;

					@include respond-to("small") {
						flex-basis: 40%;
					}
				}
			}

			button {
				flex-basis: 40%;
				flex-grow: 1;
			}
		}
	}
}
