@import "../../../../../styles/utils/utils";

.tree-radio-button {
	@include questionnaire-item-subitem-margin;
	padding: 0.5rem 0 0 0;
	display: flex;
	position: relative;
	min-height: $control-height;
	width: 100%;
	gap: get-spacing("xs");
	color: $color-text-primary;

	&:focus {
		outline: none;
	}

	&:hover {
		color: $color-text-primary;
	}

	// Pseudo radio button fill when checked
	&.tree-radio-button--selected {
		.tree-radio-button__pseudo-radio {
			&:after {
				background-color: $color-radio-border-hover;
				border: 0.35rem solid $color-radio-background;
			}
		}
	}

	// Pseudo radio button border when focused
	&:focus {
		.tree-radio-button__pseudo-radio {
			&:before {
				border: $border-radio-width solid $color-radio-border-focus;
				background: $color-radio-border-focus;
			}
		}
	}

	.tree-radio-button__pseudo-radio {
		@include font-body-normal;
		margin: 0;
		width: 2.2rem;
		height: 2.2rem;

		// Pseudo radio button border
		&:before {
			@include transition-default;
			@include pseudo-element;
			left: -1px;
			top: 3px;
			width: calc(2.2rem + 1px);
			height: calc(2.2rem + 1px);
			border: 1px solid $color-radio-background;
			border-radius: 50%;
			box-sizing: border-box;
			z-index: 0;
			background: $color-radio-border-normal;

			@at-root [dir="rtl"] & {
				left: auto;
				right: -1px;
			}
		}

		// Pseudo radio button fill
		&:after {
			@include transition-default;
			@include pseudo-element;
			left: 2px;
			top: 6px;
			width: calc(2.2rem - 5px);
			height: calc(2.2rem - 5px);
			border-radius: 50%;
			z-index: 0;
			background-color: $color-radio-background;
			border: 0.95rem solid $color-radio-background;

			@at-root [dir="rtl"] & {
				left: auto;
				right: 2px;
			}
		}
	}

	.tree-radio-button__label {
		text-align: start;
	}
}
