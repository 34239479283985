@import "../../../../styles/utils/utils";

.she-password-page-container {
	@include questionnaire-page-spacing;
	flex-grow: 1;
	padding: 0;
	margin: 0 auto;
	width: 100%;
}
.she-password-buttons {
	display: flex;
	margin: 1rem 0;
}
