@import "../../../styles/utils/utils";

.date-input {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	.date-input__hint {
		@include font-hint-normal;
		@include questionnaire-item-subitem-margin;
		color: $color-text-hint;
	}

	.date-input__button-wrapper {
		display: flex;
		align-items: flex-end;
		height: 2.5rem;
		padding-bottom: 0.4rem;
	}

	.date-input__text-input {
		max-width: 3.75rem;
		margin-inline-end: 0.8rem;

		&.date-input__text-input--long {
			max-width: 5.5rem;
		}
	}

	.date-input__label {
		font-size: smaller;
		display: block;
	}
}
