@import "../../styles/utils/utils";

.she-nav-tab__group {
	display: flex;
	width: 100%;
	justify-content: center;
	padding: 0;
	margin: 0;
	overflow: auto;
	background-color: $color-white;
	box-shadow: $shadow-small;
	z-index: get-z-index("nav-secondary");
	position: sticky;
	top: 0;
	flex-shrink: 0;

	@include respond-to("small") {
		left: 8rem;
	}

	@include respond-to("medium") {
		left: 10rem;
	}

	@include respond-to("x-large") {
		left: 12rem;
	}
}

.she-nav-tab__alert {
	position: relative;
	height: 1rem;

	&:after {
		position: absolute;
		content: "!";
		font-size: 0.6rem;
		color: $color-white;
		font-weight: $font-weight-bold;
		top: -0.25rem;
		right: -0.35rem;
		width: 0.7rem;
		height: 0.7rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-danger;
	}
}

.she-nav-tab__tab {
	@include font-guidance-normal;
	display: flex;
	flex-direction: row;
	flex-shrink: 2;
	list-style: none;
	overflow: hidden;
	flex-basis: 50%;

	@include respond-to("medium") {
		flex-basis: auto;
	}

	a {
		padding: get-spacing("xs") get-spacing("xs") calc(#{get-spacing("xs")} - 0.25rem)
			get-spacing("xs");
		outline-offset: -0.15rem;
		color: $system-colours-text;
		text-decoration: none;
		border-bottom: 0.25rem solid transparent;
		width: 100%;
		height: 2.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: border $transition-default-duration $transition-default-easing,
			outline $transition-default-duration $transition-default-easing,
			background-color $transition-default-duration $transition-default-easing;

		@include respond-to("medium") {
			padding: get-spacing("xs") get-spacing("s") calc(#{get-spacing("xs")} - 0.25rem)
				get-spacing("s");
		}

		&.she-nav-tab__tab__link--selected {
			border-bottom: 0.25rem solid $system-colours-primary;
			font-weight: bold;
		}

		&:hover {
			background-color: $color-gray-light;
			color: $color-text-primary;
			text-decoration: none;
		}

		&:focus {
			color: $color-text-primary;
			text-decoration: none;
		}
	}

	.she-nav-tab__label {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 0 get-spacing("xxs");
	}

	.she-nav-tab__pill {
		padding: 0 0.3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $system-colours-primary;
		color: $color-white;
		font-weight: $font-weight-semibold;
		font-size: $font-size-hint;
		border-radius: 0.5rem;
		height: 1rem;
		min-width: 1rem;
	}
}
