@import "src/ui/styles/utils/utils";

.she-actions {
	display: flex;
	flex-direction: column;

	&__list {
		display: flex;
		margin: 0 -0.6rem;
		padding: 0;
		list-style: none;
		align-content: flex-start;
		flex-flow: row wrap;
	}

	&__wrapper {
		padding: get-spacing("xs") 0;
	}

	&__status {
		padding-top: get-spacing("s");
	}

	&__details {
		@include font-guidance-normal;
		color: $color-text-secondary;
		text-align: right;
	}

	.she-actions__sort {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: get-spacing("xs");

		.she-actions__sort__label {
			display: inline-block;
			margin-inline-end: get-spacing("s");

			color: $color-text-secondary;
		}
	}

	.she-actions__create-task {
		position: fixed;
		bottom: 10%;
		right: 5%;
		z-index: 20;
	}

	.she-actions__create-button {
		min-width: 3rem;
		height: 3rem;
		border-radius: 1.5rem;
		gap: get-spacing("s");
		display: flex;
		align-items: center;

		&:before {
			display: none !important;
		}
	}

	.she-actions__create-task-icon {
		border-radius: 50%;
		height: 25px;
		width: 25px;
	}
}

.she-actions__header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.she-actions__create {
	margin-bottom: get-spacing("ms");
}
