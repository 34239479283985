@import "../../../../styles/utils/utils";

.she-record-list-view-wrapper {
	@include portal-page-spacing;
}

.she-record-list {
	@include questionnaire-item-spacing;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -0.4rem;

	@include respond-to("medium") {
		margin: -0.6rem;
	}
}
