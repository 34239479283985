.she-components-questionnaire-group-header {
	@include questionnaire-item-spacing;

	&:focus {
		outline: none;
	}
}

.she-components-questionnaire-group-guidance {
	margin-bottom: get-spacing("s");
	white-space: pre-wrap;

	&:empty {
		margin: 0;
	}
}
