.she-components-note {
	@include questionnaire-item-spacing;
	position: relative;

	> label {
		@include questionnaire-item-subitem-margin;
		@include font-body-bold;
		display: block;
	}

	button {
		@include font-guidance-normal;

		color: $color-link;

		&:focus {
			outline: 0;
		}
	}

	.she-components-note-container {
		display: flex;
		flex-direction: column;
	}
}

.she-components-note-tile {
	@include input-spacing;
	@include questionnaire-item-subitem-margin;
	@include transition-default(("outline", "border"));
	@include input-focus-hover;
	background-color: $color-white;
	display: flex;
	width: 100%;
	cursor: pointer;
	position: relative;

	&[aria-invalid="true"] {
		@include input-focus-hover-invalid;
	}

	.she-components-note-remove {
		position: absolute;
		top: get-spacing("xxxs");
		right: get-spacing("xxxs");

		[dir="rtl"] & {
			right: auto;
			left: get-spacing("xxxs");
		}

		button {
			svg {
				@include transition-default;
				color: $color-gray-dark;
			}

			&:hover {
				svg {
					color: $color-link-focus;
				}
			}
		}
	}
}

.she-components-note-text {
	white-space: pre-line;
	word-break: break-word;
	margin-inline-end: 1.5rem;
}
