.she-components-sync-info {
	padding: get-spacing("s");
	display: flex;
	flex-direction: column;
	height: 100%;

	> h2 {
		text-align: center;
		padding: get-spacing("m");
		flex-shrink: 0;
	}

	.she-components-sync-info-content {
		height: 100%;
		overflow: auto;

		> ul {
			padding-inline-start: 0;

			> li {
				list-style: none;
				@include transition-default;
				@include questionnaire-item-subitem-margin;
				@include transition-default(("outline", "border"));
				width: 100%;
				padding: 1rem;
				border: 0.1rem solid $color-white;
				margin-bottom: get-spacing("s");
				overflow-wrap: break-word;
				word-wrap: break-word;
				word-break: break-word;
				display: flex;
				flex-direction: column;

				.she-components-sync-info-content-title {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				.she-components-sync-info-content-error {
					@include font-guidance-normal;
				}

				.she-components-sync-info-status {
					border-radius: 50%;
					border-width: 1px;
					border-style: solid;
					width: 1rem;
					height: 1rem;
					display: inline-block;
					background-color: $color-success;
					border-color: $color-white;
				}

				.she-components-sync-info-error {
					background-color: $color-danger;
				}

				.she-components-sync-info-spinner {
					height: 1.2rem;
				}
			}
		}

		> p {
			text-align: center;
		}
	}

	.she-components-sync-info-footer {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;

		@include respond-to("small") {
			flex-direction: row;
		}

		> button {
			flex-grow: 1;
			margin-bottom: get-spacing("xs");
		}
	}
}
